import React, { useState } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components/macro';
import Waypoint from '../Waypoint';
import { createCookie } from '../../utilities';
import { setGateRelease } from '../../logic/actions';
// import { disableGate } from '../../logic/actions';
// import GateForm from './GateForm';
import HubspotForm from '../HubspotForm';
import { Paragraph, Strong } from '../Text';
import config from '../../config';

/**
 * You might need to change this based on how much space to put between it and
 * the top of its parent Section. If the lightpaper is paged, then the waypoint
 * will fire on a gated Section before this takes effect
 */
const gateOffset = '30em';

/**
 * Sets the position and dimensions of the gate, but doesn't actually center it
 * vertically with the window. See the comment over WaypointStyled for more info
 */
const GateWrapper = styled.div`
  position: ${props => (props.isStuck ? 'fixed' : 'absolute')};
  z-index: 100000;
  top: ${props => (props.isStuck ? '50%' : gateOffset)};
  left: 50%;
  transform: translateX(-50%);
`;

/**
 * Sets the actual styles of the gate, and positions it to be center of the
 * screen. Use this for theming
 */
const GateInner = styled.div`
  background: ${props => props.theme.colors.white};
  border-radius: 0.3em;
  padding: 1.5em;
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.3);
  transform: translateY(-50%);
  width: 80vw;
  max-width: 30em;
  height: 100%;
  max-height: 90vh;
  overflow: auto;
  color: ${({ theme }) => theme.colors.black};
  /* Lt gray */

  border: ${({ theme }) => `1px solid ${theme.colors.greyLight}`};
  box-shadow: ${({ theme }) => theme.shadows.primary};
  border-radius: 12px;
`;

/**
 * Triggers the waypoint by matching the position of the top of the Gate. It has
 * to match the top instead of the middle (like DotNavigation) because we don't
 * know the Gate's height, the Gate doesn't have a predictable percentage offset
 * based on its parent Section, and rendering it inside the Gate would cause it
 * to become position: fixed, too, which would break it
 */
const WaypointStyled = styled.span`
  position: absolute;
  z-index: -1;
  top: ${gateOffset};
`;

/**
 * We currently need to map innerRef to a real ref to make react-waypoint work
 * with styled-components v4
 */
export const WaypointTrigger = ({ innerRef, ...props }) => (
  <WaypointStyled ref={innerRef} {...props} />
);

const Gate = props => {
  const [isStuck, setStuck] = useState(false);

  /** When the form is submitted, disable the gate "permanently" */
  const handleGateDisable = () => {
    createCookie('gate-released', 'true', 90);
    /** Give time for the animations to finish */
    setTimeout(() => props.setGateRelease(true), 600);
  };

  return (
    <>
      <Waypoint
        topOffset="50%"
        onPositionChange={event => setStuck(event.currentPosition === 'above')}
      >
        <WaypointTrigger />
      </Waypoint>
      <GateWrapper isStuck={isStuck} isGateReleased={props.isGateReleased}>
        <GateInner>
          {/** Replace this with whatever third-party form you're using */}
          <Paragraph>
            <Strong>
              We hope you’re enjoying <em>{props.lightpaperName}</em>.
            </Strong>
          </Paragraph>
          <Paragraph>
            Complete this short form to read the entire Lightpaper
            <sup>&reg;</sup>.
          </Paragraph>
          <br />
          {/** Replace this with whatever third-party form you're using */}
          {/* <GateForm handleGateDisable={handleGateDisable} /> */}
          <HubspotForm
            id="hubspot-gate"
            formId={config.hubpostFormId}
            portalId={config.hubspotFormPortalId}
            onFormSubmitted={$form => {
              handleGateDisable();
              setTimeout(() => props.setGateRelease(true), 2000);
              return false;
            }}
          ></HubspotForm>
          {/* {isFormLoading && <SpinnerStyled />} */}
          {/* {isFormLoadingInvalidated && (
              <Paragraph>
                <strong>
                  Could not load the form. Please try refreshing the page.
                </strong>
              </Paragraph>
            )} */}
        </GateInner>
      </GateWrapper>
    </>
  );
};

const mapStateToProps = state => {
  const lightpaperName =
    state.content && state.content.length && state.content[0].metaTitle;

  return {
    isGateReleased: state.isGateReleased,
    lightpaperName: !!lightpaperName
      ? lightpaperName
      : 'If You’re Chasing ROI From Employee Mental Health, You’re Asking the Wrong Questions: 4 Ways to Measure the Value Beyond Dollars and Cents',
  };
};

const mapDispatchToProps = { setGateRelease };

export default connect(mapStateToProps, mapDispatchToProps)(Gate);
