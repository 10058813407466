import React from 'react';
import { ChartTitle, LegendContainer, LegendItem } from '../ChartElements';

export default ({ fields, ...props }) => {
  const { adminTitle, displayTheTitle, items } = fields;
  return (
    <>
      {adminTitle && displayTheTitle && <ChartTitle>{adminTitle}</ChartTitle>}
      {items && items.length && (
        <LegendContainer>
          {items.map((l, i) => {
            return (
              <LegendItem key={l} index={i}>
                {l}
              </LegendItem>
            );
          })}
        </LegendContainer>
      )}
    </>
  );
};
