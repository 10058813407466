import React, { createRef, useEffect, useState } from 'react';
import styled, { css } from 'styled-components/macro';
import { transitions } from 'polished';
import { ButtonStyles } from '../Links';
import { H3 } from '../Heading';
import Container from '../Container';
import postscribe from 'postscribe';

const PollContainer = styled.div`
  position: relative;
  z-index: 5;
  padding: 0.5em 2em;

  /** CrowdSignal overrides */
  .PDS_Poll[id*='PDI_container'] {
    * {
      font-family: ${({ theme }) => theme.fonts.sansSerif} !important;
    }

    .pds-box {
      padding: 0 !important;
      width: 100% !important;
      background-color: transparent !important;
      border: none !important;
      font-size: 1em !important;
      color: ${({ theme }) => theme.colors.black} !important;
      border-radius: 0 !important;
      font-family: ${({ theme }) => theme.fonts.sansSerif} !important;

      .pds-box-outer {
        padding: 0 !important;
      }
    }
    .pds-question {
      /** Use the contentful title field instead */
      display: none;
    }
    .pds-answer-row.pds-answer-row {
      display: flex;
      flex-direction: row;
      align-items: flex-start !important;
    }
    .pds-answer {
      border: none !important;
      .pds-input-label,
      .pds-feedback-label,
      .pds-total-votes {
        /* margin: 0.1em 0 0; */
        margin: 0;
        font-size: 1.6rem !important;
        color: ${({ theme }) => theme.colors.black};
        line-height: 1.5;
        font-weight: 500 !important;
        text-align: left;

        span {
          font-size: 1em;
        }
      }
      .pds-total-votes {
        display: none;
        /** Center the result summary text */
        span {
          text-align: center;
          justify-content: center;
        }
      }
      .pds-answer-input {
        align-self: initial !important;
      }
      #pds-sharing {
        display: none !important;
      }
    }
    .pds-vote {
      padding: 0 !important;
      /** magic numbers: width of .radio-input-icon + margin of .pds-answer-input */
      margin-left: calc(2rem + 0.5em) !important;

      .pds-votebutton-outer {
        display: inline-block;
      }
      .pds-vote-button,
      .pds-vote-button-load {
        ${ButtonStyles};
        min-width: 7em;
        min-height: 2em;
        background-image: none !important;
      }
    }
    .pds-links,
    .pds-totalvotes-outer {
      display: none !important;
    }

    /** The checkbox / radio button: */
    .pds-answer-input {
      display: flex !important;
      align-items: flex-start !important;
      justify-content: flex-start !important;
      margin-right: 0.5em !important;
      width: 2rem !important;
      height: 2rem !important;

      align-items: flex-start !important;
      justify-content: flex-start !important;
    }
    .pds-radiobutton,
    .pds-checkbox {
      ~ .radio-input-icon {
        transform: translateY(0.25em);
        /* width: 2rem;
        height: 2rem; */
        width: 100%;
        height: 100%;
        border-radius: 50%;
        cursor: pointer;
        display: block;
        background-color: ${({ theme }) => theme.colors.white};
        margin-top: -2px;
        border: 2px solid ${({ theme }) => theme.colors.greyLight};

        ${({ theme }) =>
          transitions(
            ['background-color', 'border-color'],
            theme.animations.ease
          )};

        @media screen and (max-width: ${({ theme }) =>
            theme.breakpoints.medium}) {
          font-size: 1.8rem !important;
        }
      }

      &:checked {
        ~ .radio-input-icon {
          background-color: ${({ theme }) => theme.colors.purple};
          border-color: ${({ theme }) => theme.colors.purple};
        }
      }

      &:hover {
        ~ .radio-input-icon {
          border-color: ${({ theme }) => theme.colors.purple3};
        }
      }
    }

    /** RESULTS */
    &.vote-submitted {
      /** Result bars */
      .pds-box {
        .pds-answer-feedback {
          border: none !important;
          border-radius: 32px;
          height: 1rem !important;
          background-color: ${({ theme }) =>
            theme.colors.greyLightest} !important;
          overflow: hidden;
        }
        .pds-feedback-group {
          margin-bottom: 0.5em !important;

          &:last-child {
            margin-bottom: 0 !important;
          }
        }
        .pds-feedback-label {
          font-size: 0.8em !important;

          .pds-answer-text {
            font-family: ${({ theme }) => theme.fonts.sansSerif} !important;
            font-weight: 500 !important;
            width: 100%;
            float: none !important;
            display: inline-block !important;
            flex: 1;
            overflow: unset !important;
            text-align: left;
            text-overflow: unset !important;
            white-space: unset !important;
            width: 80%;
          }

          .pds-feedback-result {
            width: 20%;
            text-align: right;

            .pds-feedback-votes {
              display: none !important;
            }
          }

          .pds-feedback-per {
            /**
             * Correct for a silly lil' non-breaking space crowdsignal adds
             * for some reason
             */
            margin-left: -0.2em;
          }
        }
        .pds-feedback-group {
          .pds-answer-feedback-bar {
            background: ${({ theme }) => theme.colors.red[0]} !important;
            height: 100% !important;
            border-radius: 29px 0px 0px 30px;
            transition: ${({ theme }) =>
              `width ${theme.animations.duration} ${theme.animations.easing}`};
          }

          /**
           * Loop through theme.sectionThemes to apply highlight colors to
           * results bars
           */
          ${props => {
            let barStyles = '';

            for (let i = 0; i < props.theme.charts.length; i++) {
              barStyles += `
                &:nth-child(${i + 1}n) .pds-answer-feedback-bar {
                  background-color: ${props.theme.charts[i]} !important;
                }
              `;
            }

            return css`
              ${barStyles}
            `;
          }}
        }
      }
    }

    @media ${props => props.theme.breakpoints.medium} {
      .pds-box {
        padding-left: 1rem !important;
        padding-right: 1rem !important;
      }
    }
  }
`;

const PollInner = styled.div`
  width: 100%;
  margin: 0 auto 2em;
  padding: 1.5em;
  border-radius: 4px;
  background: ${({ theme }) => theme.colors.white};
  border: ${({ theme }) => `1px solid ${theme.colors.greyLight}`};
  box-sizing: border-box;
  border-radius: 12px;
  /* transform: ${props =>
    props.isVisible ? `translateY(0)` : `translateY(2em)`}; */
  /* opacity: ${props => (props.isVisible ? `1` : `0`)}; */
  /* ${props =>
    transitions(['opacity', 'transform'], props.theme.animations.ease)} */

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.medium}) {
    width: 100%;
  }
`;

const PollTitle = styled(H3)`
  font-style: normal;
  font-weight: bold;
  font-size: 1.6rem;
  line-height: 1.5;
`;

/** Script to be injected into the dom: */
const pdCallbackScript = function(json) {
  var obj = JSON.parse(json);
  var pollId = obj.id;
  var pollContainerId = 'PDI_container' + pollId;

  var currentPoll = document.getElementById(pollContainerId);

  /* eslint-disable-next-line */
  if (obj.result == 'load') {
    var pdsPollAnswerInputs = currentPoll
      ? Array.from(currentPoll.querySelectorAll('.pds-answer-input'))
      : [];
    pdsPollAnswerInputs.forEach(function(input) {
      var inputRadioItem = input.querySelector(
        '.pds-radiobutton, .pds-checkbox'
      );
      if (inputRadioItem) {
        var radioInputStyled = document.createElement('label');
        radioInputStyled.setAttribute('for', inputRadioItem.id);
        radioInputStyled.classList.add('radio-input-icon');
        input.appendChild(radioInputStyled);
        inputRadioItem.style.display = 'none';
      }
    });
    /* eslint-disable-next-line */
  } else if (obj.result == 'registered' || obj.result == 'already-registered') {
    currentPoll.classList.add('vote-submitted');
  }
};

const PollBlock = props => {
  const { fields } = props;
  const { label, pollId } = fields;
  const [pollLoaded, setPollLoaded] = useState(false);
  const pollRef = createRef(null);

  /** Load the poll */
  useEffect(() => {
    if (!pollRef || !pollRef.current || !pollId || pollLoaded) {
      return;
    }

    /** Use Postscribe to asynchronously load a callback for polldaddy */
    const pdCbScript = document.createElement('script');
    pdCbScript.type = 'text/javascript';
    pdCbScript.charset = 'utf-8';
    pdCbScript.innerHTML = `var pd_callback = ${pdCallbackScript};`;

    !!pollRef.current && postscribe(pollRef.current, pdCbScript.outerHTML);

    /** Use Postscribe to asynchronously load & execute an external script */
    const pdScript = document.createElement('script');
    pdScript.type = 'text/javascript';
    pdScript.charset = 'utf-8';
    pdScript.src = `https://secure.polldaddy.com/p/${pollId}.js`;

    !!pollRef.current && postscribe(pollRef.current, pdScript.outerHTML);

    setPollLoaded(true);
    // eslint-disable-next-line
  }, []);

  const isIe11 = !!(window.navigator.userAgent.indexOf('Trident/') > -1);

  return (
    <Container size="slim">
      <PollContainer isIe11={isIe11}>
        <PollInner>
          <PollTitle>{label}</PollTitle>
          <div ref={pollRef}>
            <noscript>
              <a href={`https://poll.fm/${pollId}`}>Take the poll</a>
            </noscript>
          </div>
        </PollInner>
      </PollContainer>
    </Container>
  );
};

export default PollBlock;
