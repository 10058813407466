import React from 'react';
import styled from 'styled-components/macro';
import { transparentize } from 'polished';
// import Brush from '../svg/brush-alternate.svg';
// import Underline from '../svg/underline.svg';

const StyledTable = styled.table`
  border-spacing: 0;
  border-collapse: collapse;
  width: 100%;
`;

const TableContainer = styled.div`
  margin-bottom: 2em;
  overflow-x: auto;
`;

export const Table = props => {
  return (
    <TableContainer>
      <StyledTable {...props} />
    </TableContainer>
  );
};

export const Thead = styled.thead`
  text-align: left;
`;

export const Tbody = styled.tbody``;

export const Th = styled.th`
  border-bottom: 0.4em solid ${props => props.theme.colors.alert};
  font-size: 0.8em;
  text-transform: uppercase;
  padding: 0.4em 1em;
  white-space: pre;

  @media ${props => props.theme.breakpoints.small} {
    width: auto !important;
    padding: 0.4em;
  }
`;

export const Td = styled.td`
  padding: 0.4em 1em;
  font-size: 0.8em;
`;

export const Tr = styled.tr`
  &:nth-of-type(even) {
    background: ${props => transparentize(0.25, props.theme.colors.subtle)};
  }
`;
