import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import Link from '../Links';
import Icon from '../Icon';
import openPopup from '../SocialShare/popup';
import { ReactComponent as TwitterIcon } from './twitter.svg';
import config from '../../config';

/**
 * The tweet styles can pretty much be whatever we want. By default we'll just
 * override some styles of our base Link
 */
const TweetStyled = styled(Link)`
  /** Twitter blue */
  color: #1da1f2;

  &:hover {
    /** Twitter dark blue */
    color: #0084b4;
  }
`;

const StyledIcon = styled(Icon)`
  margin: 0 0 0.3em 0.2em;
`;

/**
 * Generate a Twitter intent link with a formatted tweet. Uses the core URL
 * parameters found here: https://developer.twitter.com/en/docs/twitter-for-websites/tweet-button/guides/web-intent.html
 *
 * @param  {string} text='' The tweet text
 * @param  {string} hashtags='' Comma-separated w/o the hash (e.g. hcm,tech)
 * @param  {string} via='' The Twitter profile to attribute the tweet
 * @return {string} The formatted Twitter link
 */
export const generateTweetLink = (text = [], hashtags = '', via = '') => {
  /** Remove some markdown formatting, such as bold text */
  const textFormatted =
    text &&
    Array.isArray(text) &&
    text.length &&
    text.reduce((acc, curr, i) => {
      let currString;
      if (typeof curr === 'string') {
        currString = curr;
      } else if (typeof curr === 'object') {
        currString =
          curr.props &&
          curr.props.children &&
          curr.props.children.length &&
          curr.props.children[0];
      }

      /** Make sure the first letter of the tweet text is capitalized */
      if (i === 0) {
        currString = currString[0].toUpperCase() + currString.substring(1);
      }

      const newStr = !!currString ? acc + currString : acc;
      return [newStr];
    }, []);
  /**
   * Generate the separate pieces of the tweet intent link. All except the URL
   * need to check for the param first, otherwise it'll render an empty section,
   * e.g. "via @" with no actual handle.
   *
   * Also set the URL params as empty strings instead of null, otherwise it'll
   * encode it literally as "null"
   */
  const textParam = textFormatted
    ? `?text=${encodeURIComponent(textFormatted)}`
    : '';
  /**
   * Get just the lightpaper root URL, strip out the location path and any URL
   * parameters. Useful for removing the gate bypass parameter
   */
  const urlParam = `&url=${encodeURIComponent(
    window.location.href.replace(/\?.*#\//i, '#/').split('/#')[0]
  )}`;
  let hashtagsParam = '';
  if (hashtags) {
    /** Remove any hashes or spaces from the provided hashtags */
    const hashtagsFormatted = hashtags.replace(/(\s|#)/gi, '');
    hashtagsParam = `&hashtags=${encodeURIComponent(hashtagsFormatted)}`;
  }
  const viaParam = via ? `&via=${encodeURIComponent(via)}` : '';
  /** Generate the full tweet intent link */
  return `https://twitter.com/intent/tweet${textParam}${urlParam}${hashtagsParam}${viaParam}`;
};

/** Put together the tweet along with a Twitter icon */
const Tweet = props => (
  <TweetStyled
    href={generateTweetLink(
      props.text || props.children,
      props.hashtags,
      props.via
    )}
    onClick={openPopup}
    /** In case the popup doesn't work */
    target="_blank"
    rel="noopener noreferrer"
  >
    {props.children}
    <StyledIcon inline icon={TwitterIcon} />
  </TweetStyled>
);

Tweet.propTypes = {
  /** The content that's displayed to the user as a link */
  children: PropTypes.node.isRequired,
  /** Optional text to use for the tweet instead of props.children */
  // text: PropTypes.string,
  /** Comma separated list of hashtags to include, without the hash */
  hashtags: PropTypes.string,
  /** Twitter profile to attribute the tweet */
  via: PropTypes.string,
};

Tweet.defaultProps = {
  text: null,
  via: config.twitterHandle,
};

export default Tweet;
