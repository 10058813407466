import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components/macro';
import MarkdownParser from '../MarkdownParser';
import Container from '../Container';
import { Button } from '../Links';
import { ReactComponent as ArrowSvg } from '../../svg/cta-arrow.svg';
import BackgroundImage from '../BackgroundImage';

const CTABlock = styled.div`
  margin: 2em 0 0;
  padding: 6em 0;
  text-align: center;
  position: ${({ hasBackgroundImage }) => hasBackgroundImage && 'relative'};

  /* background-color: ${({ theme }) => theme.colors.purplePastel}; */
`;

const ButtonContainer = styled.div`
  position: relative;
`;

const CTAButton = styled(Button)`
  margin-top: 5em !important;
`;

const ArrowStyled = styled(({ arrowPosition, ...rest }) => (
  <ArrowSvg {...rest} />
))`
  position: absolute;
  transform: translateX(-125%);
  height: auto;
  width: 6em;
  margin-top: 1.5em;
  color: ${({ theme }) => theme.colors.black};

  ${({ arrowPosition }) =>
    arrowPosition === 'left'
      ? css`
          transform: translateX(-125%);
        `
      : css`
          transform: rotateY(180deg) translateX(200%);
          left: 100%;
        `}


  @media ${({ theme }) => theme.breakpoints.medium} {
      ${({ arrowPosition }) =>
        arrowPosition === 'left'
          ? css`
              transform: translateX(-125%);
            `
          : css`
              transform: rotateY(180deg);
            `}
  }
`;

/** The most basic type of block we can render */
const CTA = ({ fields }) => {
  const { body, button, backgroundImage, arrowPosition = 'left' } = fields;

  return body || button ? (
    <CTABlock
      // isLastItem={rest.index + 1 === rest.numberOfBlocks}
      hasBackgroundImage={!!backgroundImage}
    >
      {backgroundImage && <BackgroundImage {...backgroundImage} />}
      <Container>
        <MarkdownParser>{body}</MarkdownParser>
        {button && button.buttonText && button.buttonHref && (
          <ButtonContainer>
            <ArrowStyled arrowPosition={arrowPosition} />
            <CTAButton href={button.buttonHref}>{button.buttonText}</CTAButton>
          </ButtonContainer>
        )}
      </Container>
    </CTABlock>
  ) : null;
};

CTA.propTypes = {
  fields: PropTypes.shape({
    /** Body is the only required child of fields */
    body: PropTypes.string,
  }),
  fullWidth: PropTypes.bool,
};

CTA.defaultProps = {
  isNested: false,
  fullWidth: false,
};

export default CTA;
