import Hero from '../components/blocks/Hero';
import Default from '../components/blocks/Default';
import SectionTitle from '../components/blocks/SectionTitle';
// import MediaText from '../components/blocks/MediaText';
// import TwoColumn from '../components/blocks/TwoColumn';
import Grid from '../components/blocks/Grid';
import Callout from '../components/blocks/Callout';
// import Wrapper from '../components/blocks/Wrapper';
import SentenceBar from '../components/blocks/SentenceBar';
// import Quote from '../components/blocks/Quote';
import Media from '../components/blocks/Media';
import BarChart from '../components/blocks/BarChart';
import Bubbles from '../components/blocks/Bubbles';
import Counter from '../components/blocks/Counter';
import Pictogram from '../components/blocks/Pictogram';
import CollapsibleContent from '../components/CollapsibleContent/CollapsibleContent';
import Radial from '../components/blocks/Radial';
// import Video from '../components/blocks/Video';
import ChartLegend from '../components/blocks/ChartLegend';
import HTMLBlock from '../components/blocks/HTML';
import HubspotForm from '../components/blocks/HubspotForm';
import Poll from '../components/blocks/Poll';
import TweetEmbed from '../components/blocks/TweetEmbed';
import Quote from '../components/blocks/Quote';
import CTA from '../components/blocks/CTA';

/**
 * Define default blocks available for sections, matching the content type's
 * machine name and the actual component name. If you have a content type
 * defined in the content (remote or local), it must have a block associated
 * with it or it won't display.
 *
 * For example, if you add a custom callout content type, you'll need a Callout
 * component, and it needs to be defined here as { callout: Callout }
 */
export default {
  hero: Hero,
  content: Default,
  sectionTitle: SectionTitle,
  // mediaText: MediaText,
  callout: Callout,
  grid: Grid,
  // wrapper: Wrapper,
  sentenceBar: SentenceBar,
  barChart: BarChart,
  bubbleChart: Bubbles,
  // quote: Quote,
  media: Media,
  // twoColumn: TwoColumn,
  counter: Counter,
  pictogram: Pictogram,
  collapsibleContent: CollapsibleContent,
  radial: Radial,
  // video: Video,
  html: HTMLBlock,
  chartLegend: ChartLegend,
  HubspotForm: HubspotForm,
  poll: Poll,
  tweetEmbed: TweetEmbed,
  quote: Quote,
  cta: CTA,
};
