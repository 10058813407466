import React, { useRef, useEffect, useState } from 'react';
import styled, { ThemeConsumer } from 'styled-components/macro';
import Waypoint from '../Waypoint';
import { useTrail, animated } from 'react-spring';
import { TextBox } from 'd3plus-text';
import Container from '../Container';
import {
  LegendContainer,
  LegendItem,
  ChartTitle,
  ChartIntro,
} from '../ChartElements';
import { countDecimals } from '../Counter';
// import { ReactComponent as DotSvg } from '../../svg/dot-pattern.svg';
import { ReactComponent as DotSvg } from '../../svg/nav-blob.svg';
import { transparentize } from 'polished';

/**
 * Both bar chart samples have to use an array of objects for the data key bc
 * Contentful sends the object version of data out of order
 */

/** Grouped bar chart */
// const sample = {
//   legend: [
//     'yes laksdf jlksdflkj f lksdjflkoiwei lslkf lk',
//     'no sdlkflsjdf loio roir glkdf',
//     'idunno',
//   ],
//   data: [
//     {
//       label:
//         '“Employers are invested in improving the employee experience for their workers.”',
//       values: [20, 30, 40.5],
//     },
//     { label: 'Group Label 2', values: [30, 40, 50] },
//     { label: 'Group Label 3', values: [90, 100, 55] },
//   ],
// };

// /** Single bar chart */
// const sample2 = {
//   data: [
//     { label: 'yes', values: [40] },
//     { label: 'no', values: [40] },
//     { label: 'dunno', values: [80] },
//   ],
// };

/** Validation for data structures returned from Contentful */
const checkDataStructure = data => {
  /** Check for basic structure */
  const isStructureCorrect =
    data &&
    data.data &&
    typeof Array.isArray(data.data) &&
    data.data.every(
      i => typeof i.label === 'string' && Array.isArray(i.values)
    );

  // console.log({ isStructureCorrect });

  if (!isStructureCorrect) return false;

  const highestGroupCount =
    data.data &&
    Math.max(
      ...data.data.map(
        i => i.values && Array.isArray(i.values) && i.values.length
      )
    );

  // console.log({ highestGroupCount });

  /** Check all data array lengths to make sure they're equal */
  const areArraysEqualLength = data.data.every(
    d => d.values && d.values.length === highestGroupCount
  );

  // console.log({ areArraysEqualLength });

  if (!areArraysEqualLength) return false;

  /**
   * Check the legend length against the data arrays, a nonexistent legend is
   * valid, but an empty or incomplete legend array is not
   */
  const isLegendLengthValid =
    !data.legend || (data.legend && data.legend.length === highestGroupCount);
  // console.log({ isLegendLengthValid });

  if (!isLegendLengthValid) return false;

  /** Make sure all items are numbers */
  const areValuesValid = data.data.every(
    d => d.values && d.values.every(v => !isNaN(+v))
  );

  // console.log({ areValuesValid });

  if (!areValuesValid) return false;

  if (
    isStructureCorrect &&
    areArraysEqualLength &&
    isLegendLengthValid &&
    areValuesValid
  ) {
    return true;
  }
  return false;
};

const Block = styled.div`
  margin: 2em 0 4em;
  padding-bottom: ${props => (props.isLastItem ? '2em' : 0)};
`;

const SvgContainer = styled.div`
  position: relative;
  height: 0;
  width: 100%;
  max-width: 100em;
  margin: 3em auto 1em;
  padding-bottom: ${props => props.ratio + '%'};
  margin-bottom: ${props => `-${props.ratio * 0.225}em`};
  z-index: -1;

  @media ${({ theme }) => theme.breakpoints.medium} {
    margin-bottom: ${props => `-${props.ratio * 0.1}em`};
  }
`;

const Svg = styled.svg`
  position: absolute;
  width: 100%;
  height: 100%;

  @media ${props => props.theme.breakpoints.small} {
    margin: 2em 0;
    transform: scale(1.15);
    padding: 0 1em;
    position: absolute;
  }

  .d3plus-textBox {
    /** Can't use the custom font in the d3plus-text declaration for some reason */
    text {
      font-family: ${props => props.theme.fonts.primary} !important;
    }
  }
`;

const Stop = styled.stop.attrs(({ theme, position = '0' }) => ({
  style: {
    stopColor:
      position.toString() === '0'
        ? theme.colors.orange
        : position.toString() === '1'
        ? theme.colors.pink
        : theme.colors.purple,
    stopOpacity: 1,
  },
}))``;

/** The bar that displays the data value */
/**
 * HACK: Manually filter out the dataColor prop so styled-components doesn't
 * render it in the DOM
 */
const Rect = styled(
  ({ groupIndex, valueIndex, highestGroupCount, ...props }) => {
    return <animated.rect {...props} />;
  }
)``;

const SvgText = styled(animated.text).attrs(({ theme }) => ({
  fontFamily: theme.fonts.primary,
  fill: theme.colors.white,
  fontWeight: 400,
  fontSize: '5px',
}))`
  line-height: 1;
`;

// const TextPrefix = styled.tspan`
//   font-size: 0.5em;
// `;

const TextSuffix = styled.tspan.attrs({ fontSize: '0.9em' })``;

const DotsBackground = styled(({ bgColor, ...rest }) => <DotSvg {...rest} />)`
  color: ${({ theme, bgColor }) =>
    transparentize(0.85, theme.colors[`${bgColor}`])};
  position: absolute;
  right: 16%;
  top: -4%;
  width: 50%;
  height: auto;
`;

const AxisLine = styled.line`
  stroke: ${props => props.theme.colors.subtle};
`;

const BarChart = ({ fields, ...props }) => {
  const [shouldAnimate, setAnimate] = useState(false);

  const svgRef = useRef(null);

  // const chartData = props.single ? sample2 : sample;

  const {
    chartTitle,
    chartIntro,
    chartData: chartDataRaw,
    // valuePrefix,
    valueSuffix,
    backgroundColor = 'pink',
    extraBarSpace = 10,
  } = fields;

  const { code: chartData = '{}' } = chartDataRaw;

  const chartDataParsed = !!chartData && JSON.parse(chartData);

  const { data = [], legend = null } = chartDataParsed || {};

  const isValidData = checkDataStructure(chartDataParsed);
  /** Safety check */
  if (!isValidData) {
    console.warn('Data structure is not valid for BarChart:', chartDataParsed);
  }

  const numberOfGroups = data.length;
  let numberOfValues = 0;
  /** Count each value in each group to get a total */
  data.forEach(
    i =>
      i.values &&
      Array.isArray(i.values) &&
      i.values.forEach(v => numberOfValues++)
  );
  /** Find the largest array of items in the data. They should all be the same */
  const highestGroupCount = Math.max(
    ...data.map(i => i.values && Array.isArray(i.values) && i.values.length)
  );
  const isSingleBar = highestGroupCount === 1;

  const highestNumberValue = Math.max(
    ...data.map(i => i.values && Array.isArray(i.values) && i.values)
  );

  /** Our baseline for most measurements */
  const barWidth = 8;
  const groupSpace = isSingleBar ? 6 : 8;

  /**
   * Get the total calculated height by counting the height of all bars and
   * adding that to the extra space each group adds
   */
  const svgHeight =
    numberOfValues * barWidth +
    (numberOfGroups + 6) * groupSpace +
    (isSingleBar ? groupSpace : 0);

  /**
   * Width breakdown: 50px for the bar area, 75 for the text, 25 extra on
   * right edge for hanging bar values
   */
  const svgWidth = 125;
  /** The x position that the axis and bars start */
  const barAreaStart = 50 - extraBarSpace;

  /** Used for the gradient ID: */
  const gradientId = `gradient-fill-${chartTitle
    .toLowerCase()
    .replace(/\s+/g, '-')}`;

  useEffect(() => {
    /** Add each group label to the chart with a helper wrapping function */
    data.map(({ label = '', values = [] }, index) =>
      /** Have to define all these options for the renderer */
      new TextBox()
        /** Render inside its respective bar group */
        .select(
          svgRef.current
            ? svgRef.current.querySelector(`.bar-label-group-${index}`)
            : undefined
        )
        .data([{ text: label }])
        .x(-2)
        /** Move the label up halfway the height of the bar group */
        .y(-((highestGroupCount * barWidth) / 2))
        /** We'll override this in the CSS, the custom font doesn't work here */
        .fontFamily('sans-serif')
        .fontSize(2.5)
        .fontMin(2.5)
        .fontMax(2.5)
        .lineHeight(2.5)
        .fontWeight('bold')
        .fontColor(props.theme.text)
        .width(barAreaStart)
        .height(highestGroupCount * barWidth + barWidth)
        .maxLines(4)
        .verticalAlign('middle')
        .textAnchor('end')
        .render()
    );
  }, [barAreaStart, data, groupSpace, highestGroupCount, props.theme]);

  const trail = useTrail(numberOfGroups, {
    // config: { mass: 1, tension: 120, friction: 26 },
    config: { mass: 1, tension: 500, friction: 100 },
    /** When we reset set it back to 0 immediately */
    immediate: !shouldAnimate,
    from: { percentage: 0, reverse: 100 },
    /**
     * There's no "pause" in react-spring currently, so set the start value as
     * the end too so it doesn't actually change.
     */
    to: {
      percentage: shouldAnimate ? 100 : 0,
      reverse: shouldAnimate ? 0 : 100,
    },
  });

  /** Don't risk rendering and breaking the page if the data isn't valid */
  return isValidData ? (
    <Waypoint
      topOffset="0"
      bottomOffset="25%"
      onEnter={() => setAnimate(true)}
      onLeave={() => setAnimate(false)}
    >
      <Block isLastItem={props.index + 1 === props.numberOfBlocks}>
        <Container>
          {chartTitle && (
            <ChartTitle fullWidth={props.fullWidth}>{chartTitle}</ChartTitle>
          )}
          {chartIntro && (
            <ChartIntro fullWidth={props.fullWidth}>{chartIntro}</ChartIntro>
          )}
          {/** Doing this outside the svg makes it easier */}
          {legend && legend.length && (
            <LegendContainer>
              {legend.map((l, i) => {
                return (
                  <LegendItem
                    key={l}
                    index={i}
                    highestGroupCount={highestGroupCount}
                  >
                    {l}
                  </LegendItem>
                );
              })}
            </LegendContainer>
          )}
          <SvgContainer ratio={(svgHeight / svgWidth) * 100}>
            <DotsBackground bgColor={backgroundColor} />
            <Svg
              ref={svgRef}
              viewBox={`0 0 ${svgWidth} ${svgHeight}`}
              width={`${svgWidth}px`}
              height={`${svgHeight}px`}
              style={{ overflow: 'visible' }}
            >
              <defs>
                <linearGradient
                  id={gradientId}
                  x1="0%"
                  y1="0%"
                  x2="100%"
                  y2="0%"
                >
                  <Stop offset="0%" position="0" />
                  <Stop offset="50%" position="1" />
                  <Stop offset="100%" position="2" />
                </linearGradient>
              </defs>
              {/* {legend && legend.length && (
                <g>
                  {legend.map(l => {
                    return <text>{l}</text>;
                  })}
                </g>
              )} */}
              {trail.map(({ percentage, reverse, ...rest }, groupIndex) => {
                /* const label = Object.keys(data)[groupIndex]; */
                const values = data[groupIndex] ? data[groupIndex].values : [];

                /** The 8 here is the first distance from the top of the svg */
                let base = 8 * (groupIndex + 1);
                if (groupIndex !== 0)
                  base += highestGroupCount * groupSpace * groupIndex;

                return (
                  <g key={groupIndex}>
                    {values.map((value, valueIndex) => {
                      /** Calculate the vertical position for each bar */
                      const vertical =
                        (base + (base + valueIndex * (barWidth * 2))) / 2;
                      /** 50 is the maximum bar length */
                      /** If the highestNumberValue is less than 100, then make
                       *  it the longest length possible
                       */
                      let denominator = 200;
                      if (highestNumberValue < 100) {
                        denominator = highestNumberValue * 2;
                      }
                      const length =
                        extraBarSpace + (value / denominator) * 100;

                      return (
                        <g key={`group-${value}-${groupIndex}-${valueIndex}`}>
                          <Rect
                            x={barAreaStart}
                            y={vertical - barWidth / 2}
                            width={percentage.interpolate(
                              r => (r / 100) * length
                            )}
                            height={barWidth}
                            rx={barWidth / 2}
                            stroke="none"
                            groupIndex={groupIndex}
                            valueIndex={valueIndex}
                            highestGroupCount={highestGroupCount}
                            fill={`url(#${gradientId})`}
                          />
                          <SvgText
                            alignmentBaseline="middle"
                            textAnchor="left"
                            /** Add an extra space after the bar for the text */
                            // x={barAreaStart + length + 2}
                            x={percentage.interpolate(
                              r => (r / 100) * length + (barAreaStart - 12)
                            )}
                            // dx={reverse.interpolate(r => -(r / 100) * length)}
                            /** Add a little push due to the font in this lightpaper */
                            dy="0.25"
                            y={vertical}
                            // fill="currentColor"
                          >
                            {/* TODO: This doesn't allow for the numbers to slide */}
                            {/* <TextPrefix dy="-0.25" dx="0">
                              {valuePrefix}
                            </TextPrefix> */}
                            <animated.tspan dominantBaseline="middle">
                              {percentage.interpolate(p => {
                                return (
                                  /**
                                   * Double the percentage while converting to a
                                   * decimal so the percent text is correct. Only
                                   * works when 50 is the max bar length
                                   */
                                  // ((p / 50) * length).toFixed(
                                  //   countDecimals(value)
                                  // )
                                  /** When not a percentage value */
                                  ((p / 100) * value).toFixed(
                                    countDecimals(value)
                                  )
                                );
                              })}
                            </animated.tspan>
                            {valueSuffix && (
                              <TextSuffix dy="1" dx="0">
                                {valueSuffix}
                              </TextSuffix>
                            )}
                          </SvgText>
                        </g>
                      );
                    })}
                    {/** Container for labels */}
                    <g
                      className={`bar-label-group-${groupIndex}`}
                      transform={`translate(0,${base +
                        (values.length / 2) * barWidth -
                        barWidth})`}
                    ></g>
                  </g>
                );
              })}
              {/** Render last so it sits on top of the bars */}
              <AxisLine
                x1={barAreaStart}
                y1="0"
                x2={barAreaStart}
                y2={svgHeight}
                strokeWidth=".75"
                fill="none"
              ></AxisLine>
            </Svg>
          </SvgContainer>
        </Container>
      </Block>
    </Waypoint>
  ) : null;
};

// BarChart.defaultProps = {
//   fields: {
//     backgroundColor: 'pink',
//     title: 'test',
//   },
// };

/** Wrap in a ThemeConsumer so we can use the theme directly */
export default props => (
  <ThemeConsumer>
    {theme => <BarChart {...props} theme={theme} />}
  </ThemeConsumer>
);
