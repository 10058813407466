import React, { useEffect, useState } from 'react';
import styled, { css } from 'styled-components/macro';
import Waypoint from '../Waypoint';

export const MediaBlock = styled.div`
  margin: ${props => (props.margin ? '0' : '2em')} auto;
  padding-bottom: ${props => (props.isLastItem ? '2em' : 0)};
`;

const ImageBlock = styled.div`
  // width: 100%;
  max-width: 72rem;
  margin: 4em auto;
  position: relative;
  /** Add some extra space above section hero */
  margin-top: ${({ isSectionHero }) => !!isSectionHero && '6em'};

  ${({ customStyles }) =>
    !!customStyles &&
    css`
      ${customStyles}
    `}
`;

const StyledSvg = styled.div`
  width: ${props => props.size};
  margin: 0 auto;
  ${({ alignment }) =>
    alignment && alignment.toLowerCase() === 'left'
      ? css`
          margin-left: 0;
        `
      : alignment && alignment.toLowerCase() === 'right'
      ? css`
          margin-right: 0;
        `
      : null}

  svg {
    display: block;
    width: 100%;
    max-width: 100%;
    height: auto;
    margin: 0 auto;
    ${props =>
      !!props.isIe11 && !!props.svgHeight && `height: ${props.svgHeight}px;`};
  }
`;

const StyledImage = styled.img`
  display: block;
  max-width: 100%;
  height: auto;

  ${({ bleedContainer, size, imageAlign, theme }) =>
    bleedContainer &&
    css`
      @media ${theme.breakpoints.smallMin} {
        max-width: initial;
        width: ${size};
        position: absolute;
        position: relative;
        left: ${() =>
          !!imageAlign && imageAlign === 'left'
            ? '0%'
            : imageAlign && imageAlign === 'right'
            ? '-30%'
            : null};
      }
    `}
`;

const Media = ({
  src,
  fields,
  isNested,
  className,
  parentElement,
  mediaGridAlign,
  mediaGridWidth,
  ...rest
}) => {
  const { image, customWidth, alignment, customStyles } = fields;
  const [svg, setSvg] = useState('');

  useEffect(() => {
    const isSvg = fileName => fileName && fileName.match(/\.svg/);

    async function fetchData() {
      await fetch(image)
        .then(response => response.text())
        .then(body => setSvg(body));
    }
    image && isSvg(image) && fetchData();
  }, [image]);

  // const alt = image.fields && (image.fields.description || image.fields.title);

  const isIe11 = !!(window.navigator.userAgent.indexOf('Trident/') > -1);

  /** IE fallback to add a height to svg elements */
  const svgHeightMatch = isIe11 && svg && svg.match(/height="(\d+\.?\d.)"/);
  const svgHeight =
    svgHeightMatch && svgHeightMatch.length && svgHeightMatch[1];

  const bleedContainer = !!parentElement && parentElement === 'grid';

  return (
    <Waypoint topOffset="0" bottomOffset="30%">
      <ImageBlock
        isLastItem={rest.index + 1 === rest.numberOfBlocks}
        customStyles={
          !!customStyles &&
          customStyles.lang &&
          customStyles.lang.toLowerCase() === 'css' &&
          customStyles.code
        }
      >
        {!!svg ? (
          <StyledSvg
            // alt={alt}
            alignment={alignment}
            size={customWidth}
            dangerouslySetInnerHTML={{ __html: svg }}
            isIe11={isIe11}
            svgHeight={svgHeight}
          />
        ) : (
          <StyledImage
            src={image}
            size={mediaGridWidth || customWidth}
            className={className}
            bleedContainer={bleedContainer}
            imageAlign={bleedContainer && mediaGridAlign}
          ></StyledImage>
        )}
      </ImageBlock>
    </Waypoint>
  );
};

Media.defaultProps = {
  fields: {
    width: '100%',
    justifyContent: 'flex-start',
    magnify: false,
  },
  isNested: true,
};

export default Media;
