import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import MarkdownParser from '../MarkdownParser';
import Container from '../Container';
import BackgroundImage from '../BackgroundImage';

const DefaultBlock = styled.div`
  margin: 2em 0 4em;
  padding-bottom: ${props => (props.isLastItem ? '2em' : 0)};
  position: ${({ hasBackgroundImage }) => hasBackgroundImage && 'relative'};
`;

/** The most basic type of block we can render */
const Default = props => {
  const { fields, isNested, fullWidth, ...rest } = props;
  const { body, backgroundImage } = fields;

  return body ? (
    <DefaultBlock
      isLastItem={rest.index + 1 === rest.numberOfBlocks}
      hasBackgroundImage={!!backgroundImage}
    >
      {backgroundImage && <BackgroundImage {...backgroundImage} />}
      <Container>
        <MarkdownParser>{body}</MarkdownParser>
      </Container>
    </DefaultBlock>
  ) : null;
};

Default.propTypes = {
  fields: PropTypes.shape({
    /** Body is the only required child of fields */
    body: PropTypes.string,
  }),
  fullWidth: PropTypes.bool,
};

Default.defaultProps = {
  isNested: false,
  fullWidth: false,
};

export default Default;
