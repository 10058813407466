import React from 'react';
import styled, { css } from 'styled-components/macro';
import MarkdownParser from '../MarkdownParser';
import Container from '../Container';
import Waypoint from '../Waypoint';
import { H1 } from '../Heading';
import { Eyebrow, Intro } from '../Text';
// import { InlineSVG } from '../../utilities';

export const HeroStyled = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: center;
  align-content: center;
  min-width: 100%;
  margin-top: 8rem;
  padding: 5em 0 2.5em;
  position: relative;
  height: 60vh;
  min-height: 40em;
  max-height: 40vw;

  ${({ backgroundImage }) =>
    !!backgroundImage &&
    css`
      background-image: url(${backgroundImage});
      background-size: cover;
      background-position: bottom center;
      background-repeat: no-repeat;
    `}

  ${Intro} {
    max-width: 66%;
    font-size: 3.4rem;
    line-height: 1.35;
    margin-top: 1em;
  }

  ${({ theme }) => `@media ${theme.breakpoints.medium}`} {
    min-height: 36em;
    max-height: 60vw;

    ${Intro} {
      max-width: 100%;
    }
  }

  ${({ theme }) => `@media ${theme.breakpoints.small}`} {
    min-height: 28em;
    max-height: 80vw;
  }
`;

const HeroContainer = styled(Container)`
  position: relative;
  color: ${({ theme }) => theme.colors.white};

  ${H1} {
    font-size: 10rem;
    line-height: 1;
    margin-bottom: 0.25em;

    @media ${({ theme }) => theme.breakpoints.medium} {
      font-size: 8rem;
    }

    @media ${({ theme }) => theme.breakpoints.small} {
      font-size: 6rem;
    }
  }
`;

const HeroEyebrow = styled(Eyebrow)`
  font-size: 4rem;
  background-color: ${({ theme }) => theme.colors.green};
  display: inline-block;
  padding: 0.05em 0.4em;
  text-transform: initial;
  margin: 0 auto;

  @media ${({ theme }) => theme.breakpoints.medium} {
    font-size: 3.4rem;
  }
`;

// const foregroundImageStyles = css`
//   ${'' /* max-width: 80%; */}
//   position: relative;
//   z-index: 1;
//   margin: 3em auto 0;
//   display: block;
// `;

// const StyledImg = styled.img`
//   ${foregroundImageStyles}
// `;

// const StyledSvg = styled(InlineSVG)`
//   ${foregroundImageStyles}
//   svg {
//     height: auto;
//     max-width: 100%;
//   }
// `;

const Hero = props => {
  const { fields } = props;
  const { title, eyebrow, backgroundImage /*, foregroundImage */ } = fields;

  // const isSvg = fileName => fileName && fileName.match(/\.svg/);

  return (
    <HeroStyled backgroundImage={backgroundImage || null}>
      <Waypoint topOffset="0" bottomOffset="0">
        <HeroContainer size="wide">
          {title && <MarkdownParser>{title}</MarkdownParser>}
          {eyebrow && <HeroEyebrow>{eyebrow}</HeroEyebrow>}
          {/* {foregroundImage && isSvg(foregroundImage) ? (
            <StyledSvg
              className="hero-foreground-image"
              url={foregroundImage}
            />
          ) : foregroundImage ? (
            <StyledImg src={foregroundImage} />
          ) : null} */}
        </HeroContainer>
      </Waypoint>
    </HeroStyled>
  );
};

export default Hero;
