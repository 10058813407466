/** Define common colors, fonts, and other branding here */
const colors = {
  white: '#fff',
  black: '#1c1c1c',
  grey: '#e8e8e8',

  /** Brand colors */
  purple: '#7c21cc',
  purplePastel: '#fadcff',
  yellow: '#f4e157',
  yellowPastel: '#fcf1cb',
  pink: '#f11c61',
  pinkPastel: '#ffcde1',
  green: '#68d78f',
  greenLight: '#c1e8c9',
  greenHighlight: '#69ffde',
  greenHighlightPastel: '#82f7dc',
  blue: '#79e7da',
  bluePastel: '#9deef4',
  orange: '#f47351',
  orangePastel: '#ffcdce',
};

const charts = [
  colors.pink,
  colors.orange,
  colors.green,
  colors.purple,
  colors.yellow,
];

const gradient = `linear-gradient(90deg, ${colors.purple} 0%, ${colors.pink} 50%, ${colors.orange} 100%)`;

/** These all need the same keys so each themed section can expect them */
// const sectionThemes = {
//   default: {
//     primary: colors.black,
//     secondary: colors.black,
//     highlight1: colors.black,
//     highlight2: colors.black,
//     highlight3: colors.black,
//     highlight4: colors.black,
//     charts: [...charts],
//   },
// };

const fonts = {
  serif: '"Rockness", serif',
  sansSerif: '"Open Sans", sans-serif;',
  primary: '"Open Sans", sans-serif;',
  secondary: '"Open Sans", sans-serif;',
};

const sizes = {
  containerWidthSlim: '78rem',
  containerWidth: '92rem',
};

const breakpoints = {
  small: `screen and (max-width: 35em)`,
  smallMin: `screen and (min-width: 35em)`,
  medium: `screen and (max-width: 64em)`,
  large: `screen and (max-width: 96em)`,
  xlarge: `screen and (max-width: 110em)`,
  minHeight: `screen and (max-height: 37em)`,
};

const shadows = {
  primary: '0px 0px 20px 3px rgba(45, 41, 38, 0.12);',
};

const timing = {
  ease: '0.3s',
  svgAnimation: '2s',
};

const animations = {
  ease: `${timing.ease} ease`,
  // smoothTransitionBezier: '.4s cubic-bezier(0.11, 0.59, 0.32, 0.9)',
};

const theme = {
  colors,
  charts,
  gradient,
  fonts,
  sizes,
  breakpoints,
  shadows,
  timing,
  animations,
  /**
   * Assign each theme item so we can pick a primary color scheme per section.
   * We can also access each item per section individually in case we want to
   * do some overrides
   */
  // ...sectionThemes,
};

export default theme;
