/** For some reason this works, but react-app-polyfill/stable doesn't */
import 'core-js/stable';
import 'react-app-polyfill/ie11';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';
import { ThemeProvider } from 'styled-components/macro';
/** Lightpaper components */
import AppHead from './containers/AppHead';
import Routes from './containers/Routes';
import GlobalStyle from './components/GlobalStyle';
import ScrollToSection from './components/ScrollToSection';
/** Logic modules */
import store, { history } from './logic/store';
import {
  loadContent,
  headerIsUnderFirstSectionSet,
  setGateRelease,
  disableGate,
  setLocale,
} from './logic/actions';
import { readCookie, getUrlParameter, removeUrlParameter } from './utilities';
/** Config */
import config from './config';
import theme from './config/theme';
/** And this guy */
// import registerServiceWorker from './registerServiceWorker';
import { unregister } from './registerServiceWorker';

/** Initiate loading content, defining whether the content is local or remote */
store
  .dispatch(loadContent(config.shouldUseRemoteContent, config.contentful))
  /**
   * Check for a query search param `locale`, and set that locale if we have it
   */
  .then(() => {
    const queryParams = new URLSearchParams(window.location.search);
    const localeParam = queryParams.get('locale');
    if (!localeParam) {
      return;
    }

    const isLocaleParamValid =
      config.locales.filter(
        locale => locale.code.toLowerCase() === localeParam.toLowerCase()
      ).length > 0;

    !!isLocaleParamValid && store.dispatch(setLocale(localeParam));
  });

/** Save a step and check for this config option before dispatching it */
if (
  config.isHeaderUnderFirstBlock ||
  config.isHeaderUnderFirstBlock === false
) {
  store.dispatch(headerIsUnderFirstSectionSet(config.isHeaderUnderFirstBlock));
}

/** If the lightpaper should have a full bypass option for users, set this */
let gateBypass = 'gate_bypass';
// gateBypass = 'gir';

if (gateBypass && /^true\/?$/.test(getUrlParameter(gateBypass))) {
  /** Remove the gate */
  store.dispatch(disableGate());
  /** Then make sure we remove that URL parameter so it can't be shared */
  const replacement = new URL(removeUrlParameter(gateBypass));
  /**
   * Use basic window history replacement instead of the connected router bc
   * this happens before the first rendering of the router, so trying to use it
   * won't take effect
   */
  // store.dispatch(push(newPath));
  window.history.replaceState(
    {},
    document.title,
    replacement.toString().replace(replacement.origin, '')
  );
} else if (
  /**
   * Initial check for releasing the gate, without setting the gate cookie. This
   * parameter is for internal use only
   */
  getUrlParameter('nogate') === 'true' ||
  readCookie('gate-released')
) {
  store.dispatch(setGateRelease(true));
  /**
   * Check for a special URL parameter to remove the gate and add a cookies.
   * Sometimes, because of the way the hash-router path works, the parameter may
   * be "true/" instead of "true"
   */
}

/** The entry point to the lightpaper */
ReactDOM.render(
  <Provider store={store}>
    <ThemeProvider theme={theme}>
      <ConnectedRouter history={history}>
        <>
          <GlobalStyle />
          <AppHead />
          <main>
            <Routes />
          </main>
          <ScrollToSection />
        </>
      </ConnectedRouter>
    </ThemeProvider>
  </Provider>,
  document.getElementById('root')
);

// registerServiceWorker();
unregister();
