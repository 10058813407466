import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import Waypoint from '../Waypoint';
import Section from '../../containers/Section';

/**
 * Trigger the navigation to change when a new section is scrolled to. Also
 * only changes the navigation when it's set as enabled to prevent programmatic
 * scrolling functions falsely triggering it
 */
const SectionWaypoint = props => (
  <Waypoint
    topOffset="40%"
    bottomOffset="20%"
    /** onEnter has consistency issues. This is better */
    onPositionChange={
      props.isSectionWaypointEnabled
        ? event => {
            const cp = event.currentPosition;
            const pp = event.previousPosition;

            /**
             * If the current item is now in the "active" area and not
             * already active, set it as active
             */
            if (
              (cp === 'inside' || cp === 'above') &&
              props.location.pathname !== props.path
            ) {
              return props.handleHistoryUpdate({
                path: props.path,
                isScrolled: true,
              });
              /**
               * Or, if the active item is below the active area, set
               * the previous item as active
               */
            } else if (cp === 'below' && (pp === 'inside' || pp === 'above')) {
              return props.handleHistoryUpdate({
                path: props.menu[props.index - 1].path,
                isScrolled: true,
              });
            }

            return false;
          }
        : undefined
    }
  />
);

/**
 * Create a Route for each section of the content, and render them all inline.
 * Also includes a waypoint that will update the URL location when a new section
 * is scrolled to
 */
const InlineRoutes = ({
  menu,
  handleHistoryUpdate,
  isSectionWaypointEnabled,
}) => {
  const firstGatedItem = menu.findIndex(item => item.isGated);
  return (
    <>
      {menu.map((item, index) => {
        return (
          <Route
            key={item.id}
            path={item.path}
            /**
             * Use children instead of render so the component is always
             * rendered, regardless of whether the path matches
             */
            children={({ location }) => {
              return (
                <>
                  <SectionWaypoint
                    isSectionWaypointEnabled={isSectionWaypointEnabled}
                    location={location}
                    menu={menu}
                    handleHistoryUpdate={handleHistoryUpdate}
                    path={item.path}
                    index={index}
                  />
                  <Section
                    isActive={location.pathname === item.path}
                    itemId={item.id}
                    index={index}
                    sectionCount={menu.length}
                    firstGatedItem={firstGatedItem}
                  />
                </>
              );
            }}
          />
        );
      })}

      {/** Redirect invalid URL paths to the root */}
      <Route
        render={({ location }) => {
          /**
           * Check if there were any other Routes rendered, otherwise this will
           * run on initial page load, causing potentially valid URL paths to
           * be redirected to the root
           */
          if (menu.length) {
            /** If the current URL path doesn't match any content paths */
            const isCurrentPathValid =
              menu.findIndex(item => item.path === location.pathname) >= 0;
            return !isCurrentPathValid ? <Redirect to="/" /> : null;
          }
          return null;
        }}
      />
    </>
  );
};

export default InlineRoutes;
