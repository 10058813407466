import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components/macro';
import { transparentize } from 'polished';
import Waypoint from '../Waypoint';

const activeClassName = 'nav-active';

/** The nav itself */
const Nav = styled.nav`
  position: ${props => (props.isStuck ? 'fixed' : 'absolute')};
  z-index: 900;
  padding: 1em 2em 1em 1em;
  font-size: 2rem;
  /**
   * When not stuck, the nav will first show to the user at half the window's
   * height past the start of the second section
   */
  top: ${props => (props.isStuck ? '50%' : 'calc(100% + 50vh)')};
  right: 0%;
  transform: translateY(-50%);

  @media ${({ theme }) => theme.breakpoints.small} {
    padding: 1em;
  }
`;

/** The react-router link for each nav item */
const NavItem = styled(NavLink)`
  display: block;
  position: relative;
  line-height: 1;
  margin-bottom: 1em;
  color: ${({ theme }) => transparentize(0.5, theme.colors.purple)};
  transition: color 0.2s ease-in-out;

  &:hover,
  &:focus {
    color: ${({ theme }) => theme.colors.pink};
  }

  &.${activeClassName} {
    color: ${({ theme }) => theme.colors.green};
  }
`;

/** The actual dot for a nav item */
const Dot = styled.span`
  border-radius: 100%;
  width: 3rem;
  height: 3rem;
  display: block;
  position: relative;
  color: inherit;
  /* border: 1px solid ${({ theme }) => theme.colors.black}; */

  &:after {
    content: '';
    position: absolute;
    display: block;
    width: 100%;
    height: 100%;
    // background: ${({ theme }) => theme.colors.white};
    background: ${({ theme }) => theme.colors.grey};
    border-radius: 50%;
    z-index: -1;
    transition: ${({ theme }) => `background-color ${theme.animations.ease}`};
  }

  .${activeClassName} & {
    &:after {
      background: ${({ theme }) => theme.colors.green};
    }
  }

  ${NavItem}:hover & {
    &:after {
      background: ${({ theme }) => theme.colors.purple};
    }
  }
`;

/**
 * Wrap the styled title to give it a max-width while still allowing the visible
 * Title to be smaller, which doesn't work when applying the width directly to
 * the Title. That happens bc its parent container's width (Nav) is smaller than
 * the Title
 */
const TitleWrapper = styled.div`
  position: absolute;
  z-index: -1;
  right: 100%;
  top: 50%;
  transform: translateY(-50%);
  width: 20em;
  text-align: right;
  pointer-events: none;
  /** When hovered over, this gives some extra space around the title */
  padding: 2em 1em 2em 0;
  font-size: 1.4rem;

  ${NavItem}:hover & {
    pointer-events: auto;
  }
`;

/** The text for the nav item */
const Title = styled.span`
  display: inline-block;
  position: relative;
  opacity: 0;
  visibility: hidden;
  max-width: 100%;
  text-align: left;
  transition: opacity 0.3s ease, transform 0.15s ease, visibility 0s ease;
  background: ${props => props.theme.colors.white};
  padding: 1em 2em;
  font-size: 1em;
  color: ${props => props.theme.colors.black};
  transform: translateX(2em);
  font-weight: 600;
  /* box-shadow: 0 6px 20px rgba(0, 0, 0, 0.19), 0 2px 6px rgba(0, 0, 0, 0.23); */
  box-shadow: ${props => props.theme.shadows.primary};
  border-radius: 100em;

  ${NavItem}:hover & {
    visibility: visible;
    opacity: 1;
    transform: translateX(0);
  }
`;

/**
 * Center the waypoint vertically in the dot navigation, so it triggeres when
 * the middle of the nav hits the middle of the window
 */
const WaypointStyled = styled.span`
  position: absolute;
  z-index: -1;
  top: calc(100% + 50vh);
  transform: translateY(-50%);
  font-size: 0;
`;

/**
 * Take each menu item and output it as a "dot" style navigation, which becomes
 * sticky in the middle of the window vertically and hides its menu title until
 * the user interacts with each dot
 */
const DotNavigation = ({ menuItems, location }) => {
  const [isStuck, setStuck] = useState(false);

  return (
    <>
      <Waypoint
        topOffset="50%"
        onPositionChange={event => setStuck(event.currentPosition === 'above')}
      >
        <WaypointStyled />
      </Waypoint>
      <Nav isStuck={isStuck}>
        {menuItems.map((item, index) => (
          <NavItem
            exact
            /**
             * There's something wonky with the scrolling part of the route
             * changing, so this helps ensure that the active item is always
             * the right path
             */
            isActive={() => location.pathname === item.path}
            index={index}
            key={`menu-${item.id}`}
            to={item.path}
            activeClassName={activeClassName}
          >
            <TitleWrapper index={index}>
              <Title index={index}>{item.title}</Title>
            </TitleWrapper>
            <Dot index={index} />
          </NavItem>
        ))}
      </Nav>
    </>
  );
};

export default DotNavigation;
