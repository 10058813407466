import styled from 'styled-components/macro';
import { transitions } from 'polished';

export const Button = styled.button`
  display: inline-block;
  background: linear-gradient(
    to right,
    #92fcf4 50%,
    ${props => props.theme.colors.callout} 50%
  );
  background-size: 200% 100%;
  background-position: right bottom;
  border-radius: 3px;
  border: none;
  width: auto;
  font-weight: 700;
  padding: 1rem 3rem;
  margin-top: 0.5em;
  margin-bottom: 1.5em;
  cursor: pointer;
  ${props =>
    transitions(
      ['color', 'background', 'transform'],
      props.theme.animations.smoothTransitionBezier
    )}

  &:hover {
    background-position: left bottom;
  }
`;
