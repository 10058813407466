import React from 'react';
import Markdown from 'markdown-to-jsx';
/** Import some prebuilt components */
import { H1, H2, H3, H4 } from './Heading';
import {
  Paragraph,
  Eyebrow,
  Intro,
  IntroStyle,
  Span,
  UL,
  OL,
  LI,
} from './Text';
import { Table, Thead, Tbody, Th, Td, Tr } from './Table';
import Link, { Button } from './Links';
import Image from './Image';
import Tweet from './Tweet/Tweet';
import Radial from './Radial';
import Tooltip from './Tooltip/Tooltip';
import Counter from './blocks/Counter';
import Flutter from './Flutter';
import Divider from './Divider';
import Modal from './Modal';
import CollapsibleContent from './CollapsibleContent/CollapsibleContent';
import HubspotForm from './blocks/HubspotForm';

/**
 * Our default Markdown parser. This can and should be overriden in the theme
 * with whatever extra components and styles are needed. The components included
 * here are built-in lightpaper components
 */
const MarkdownParser = props => {
  /**
   * HACK: If some special components are the first element within a paragraph
   * in the Markdown, and don't encompass the entire paragraph, the paragraph
   * tag won't be added in when it's converted to JSX, so add a crazy-ass string
   * id to the beginning. This will force the Markdown parser to render the
   * paragraph correctly.
   *
   * The first attempt was to manually wrap the problem string in a paragraph,
   * but the parser just added nested paragraphs to subsequent strings.
   *
   * Also wrap a couple extra components that aren't naturally paragraphs, like
   * Tweets, in a paragraph if they encompass the whole string
   *
   * E.g. that we're fixing:
   * `<Tweet>Tweet text link.</Tweet> Continuing with my sentence.`
   * becomes:
   * `<p><Tweet>Tweet text link.</Tweet> Continuing with my sentence.</p>`
   *
   * E.g. that works fine:
   * `<Intro>Intro paragraph text.</Intro>`
   */
  /**
   * Create a key that hopefully would never be recreated in Contentful.
   * Major hack
   */
  // const key = 'REMOVALID1324Ξ';
  // const formattedChildren = props.children
  //   .replace(
  //     /^(<[Tweet|Intro]*.*?<\/[Tweet|Intro]*>[^\n|$].*)/gim,
  //     // '<p>$1</p>'
  //     `${key}$1`
  //   )
  //   .replace(/^(<[Tweet]*.*?<\/[Tweet]*>)([\n])*$/gim, `<p>$1</p>$2`);

  // console.log(formattedChildren);
  return (
    <Markdown
      options={{
        forceBlock: true,
        // createElement(type, props, children) {
        //   /** Remove that identifier */
        //   // console.log(type, children);
        //   if (children && children[0] && typeof children[0] === 'string') {
        //     children[0] = children[0].replace(key, '');
        //   }
        //   /**
        //    * HACK: The above hacks help to ensure some elements always have a
        //    * wrapping `<p />`, but in some cases block components will also be
        //    * rendered with a wrapping paragraph. This usually happens if there's
        //    * more Markdown to render inside the component. In these cases we
        //    * want to manually pull the children elements out of the new wrapping
        //    * paragraph tag so they'll be compiled
        //    *
        //    * E.g. this:
        //    * `<Intro>Test content with *emphasis*</Intro>`
        //    *
        //    * Compiles to this (wrong):
        //    * `<p><Intro>Test content with <em>emphasis</em></Intro></p>`
        //    *
        //    * With this fix it compiles to this (correct):
        //    * `<Intro>Test content with <em>emphasis</em></Intro>`
        //    *
        //    */
        //   if (
        //     children &&
        //     children[0] &&
        //     typeof children[0] === 'object' &&
        //     children[0].props &&
        //     children[0].props.children &&
        //     type &&
        //     /** Add as many components as you want */
        //     type.displayName === Intro.displayName &&
        //     type.displayName === Tweet.displayName &&
        //     type.displayName !== Flutter.displayName &&
        //     type.displayName !== Table.displayName &&
        //     type.displayName !== Tbody.displayName &&
        //     type.displayName !== Td.displayName &&
        //     type.displayName !== Tr.displayName
        //   ) {
        //     children[0] = children[0].props.children;
        //   }
        //   return React.createElement(type, props, children);
        // },
        overrides: {
          h1: { component: H1 },
          h2: { component: H2 },
          h3: { component: H3 },
          h4: { component: H4 },
          H1: { component: H1 },
          H2: { component: H2 },
          H3: { component: H3 },
          H4: { component: H4 },
          p: { component: Paragraph },
          Paragraph: { component: Paragraph },
          Eyebrow: { component: Eyebrow },
          Intro: { component: Intro },
          span: { component: Span },
          Span: { component: Span },
          IntroStyle: { component: IntroStyle },
          a: { component: Link },
          Link: { component: Link },
          Hashlink: { component: Link },
          img: { component: Image },
          Image: { component: Image },
          Tweet: { component: Tweet },
          Radial: { component: Radial },
          Tooltip: { component: Tooltip },
          Counter: { component: Counter },
          Flutter: { component: Flutter },
          Collapsible: { component: CollapsibleContent },
          HubspotForm: { component: HubspotForm },
          Divider: { component: Divider },
          /** Add any additional overrides here */
          ul: { component: UL },
          ol: { component: OL },
          li: { component: LI },
          UL: { component: UL },
          OL: { component: OL },
          LI: { component: LI },
          table: { component: Table },
          thead: { component: Thead },
          tbody: { component: Tbody },
          th: { component: Th },
          tr: { component: Tr },
          td: { component: Td },
          button: Button,
          Button: Button,
          Modal: Modal,
          ...props.overrides,
        },
      }}
    >
      {props.children}
      {/* {formattedChildren} */}
    </Markdown>
  );
};

// MarkdownParser.propTypes = {
//   children: PropTypes.node.isRequired,
// };

export default MarkdownParser;
