import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import Radial from '../Radial';
import C from '../Container';
import MarkdownParser from '../MarkdownParser';
import { CaptionBody } from '../ChartElements';

const Block = styled.div`
  width: 100%;
  margin: 2em 0;
  padding-bottom: ${props => (props.isLastItem ? '2em' : 0)};

  @media ${props => props.theme.breakpoints.small} {
    margin-left: auto;
    margin-right: auto;
    text-align: center;
  }
`;

const Container = styled(C)`
  margin: 0 auto;
`;

const RadialOuter = styled.div`
  width: 100%;
  margin: 0.5em auto;
  max-width: 10em;

  @media ${props => props.theme.breakpoints.small} {
    margin: 0 auto 0.5em;
  }
`;

const RadialBody = styled.div`
  display: flex;
  flex-flow: row;
  align-items: flex-start;
`;

const IntroStyled = styled.div`
  margin: 0.5em auto 0;
  text-align: center;
`;

const RadialGraph = ({ fields, index, ...rest }) => {
  const { number, caption, chartColor } = fields;
  return (
    <Block isLastItem={rest.index + 1 === rest.numberOfBlocks}>
      <Container>
        <RadialOuter>
          <Radial
            chartColor={chartColor || null}
            index={index}
            data={String(number)}
          />
        </RadialOuter>
        <RadialBody>
          <IntroStyled>
            <MarkdownParser
              overrides={{
                p: { component: CaptionBody },
              }}
            >
              {caption}
            </MarkdownParser>
          </IntroStyled>
        </RadialBody>
      </Container>
    </Block>
  );
};

RadialGraph.propTypes = {
  fields: PropTypes.shape({
    caption: PropTypes.string,
    number: PropTypes.number,
  }).isRequired,
};

export default RadialGraph;
