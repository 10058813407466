import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled, { withTheme } from 'styled-components/macro';
import { transparentize } from 'polished';
import Modal from 'react-modal';
import { Button } from './Button';

const FooterCTA = styled(Button)`
  text-decoration: none;
  appearance: none;
  color: ${props => props.theme.colors.primary};
  border-radius: 0;
  font-weight: 600;
  position: relative;
  padding: 1rem 3rem 1.5rem;
  display: inline-block;
  font-size: 2em;
  font-family: ${props => props.theme.fonts.secondary};
  color: ${props => props.theme.colors.alert};
  font-weight: normal;
  margin-bottom: 1em;

  background: transparent;
  background-size: 200% 100%;
  background-position: right bottom;

  &::before {
    display: ${props => (props.unstyled ? 'none' : 'block')};
    content: '';
    z-index: -1;
    background: ${props => props.theme.colors.background};
    transform: skew(-8deg);
    height: 80%;
    bottom: 0;
    left: 5%;
    width: 0%;
    position: absolute;
    opacity: 0.5;
    transition: all ${props => props.theme.animations.ease};
  }

  &::after {
    display: ${props => (props.unstyled ? 'none' : 'block')};
    user-select: none;
    content: '';
    z-index: -1;
    position: absolute;
    bottom: -0.05em;
    right: 0;
    left: 0.5em;
    height: 0.2em;
    width: 100%;
    background: ${props => props.theme.colors.background};
    transition: all ${props => props.theme.animations.ease} 0.6s;
    content: '';
    display: block;
    background-size: contain;
    width: 85%;
    height: 2rem;
    margin: -0.2em 0 0 -0.2em;
    bottom: auto;
    top: 80%;
  }

  &:hover,
  &:active,
  &:focus {
    outline: none;
    background: transparent;

    &::before {
      width: 85%;
    }
  }
`;

const ModalBlock = props => {
  const [isOpen, setIsOpen] = useState(false);

  /**
   * Modal style override options:
   *
   * http://reactcommunity.org/react-modal/styles/
   */
  const modalStyles = {
    /** The div that overlays all page content: */
    overlay: {
      backgroundColor: transparentize(0.5, props.theme.colors.primary),
      zIndex: '10',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      overflow: 'auto',
    },
    /** The div holds `props.children`: */
    content: {
      position: 'static',
      top: 'initial',
      left: 'initial',
      right: 'initial',
      bottom: 'initial',
      marginRight: 'initial',
      transform: 'initial',
      padding: '0',
      marginTop: '2em',
      marginBottom: '2em',
      backgroundColor: 'transparent',
      borderColor: 'transparent',
    },
  };

  const ModalButtonComponent =
    props.buttonStyle.toLowerCase() === 'cta' ? FooterCTA : Button;

  return (
    <>
      <ModalButtonComponent onClick={() => setIsOpen(true)}>
        {props.buttonText}
      </ModalButtonComponent>
      <Modal
        isOpen={isOpen}
        // onAfterOpen={afterOpenModal}
        ariaHideApp={false}
        onRequestClose={() => setIsOpen(false)}
        style={modalStyles}
        contentLabel="Example Modal"
      >
        {props.children}
      </Modal>
    </>
  );
};

ModalBlock.propTypes = {
  buttonText: PropTypes.string,
  buttonStyle: PropTypes.oneOf(['button', 'cta']),
};

ModalBlock.defaultProps = {
  buttonText: '',
  buttonStyle: 'button',
};

// Counter.propTypes = {
//   /** Values should be strings to avoid some number animation issues */
//   start: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
//   end: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
//   prefix: PropTypes.string,
//   suffix: PropTypes.string,
// };

// Counter.defaultProps = {
//   start: 0,
//   prefix: '',
//   suffix: '',
// };

export default withTheme(ModalBlock);
