import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components/macro';
import contentBlocks from '../../config/contentBlocks';
import Container from '../Container';

const GridBlock = styled.div`
  margin: 2em auto 0;
  padding-bottom: ${props => (props.isLastItem ? '2em' : 0)};
`;

const GridContainer = styled.div`
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  margin: 0 -1.5em;
  align-items: flex-start;
  ${props =>
    props.itemAlignment &&
    props.itemAlignment.toLowerCase() === 'center' &&
    'align-items: center;'};
  ${props =>
    props.itemAlignment &&
    props.itemAlignment.toLowerCase() === 'bottom' &&
    'align-items: flex-end;'};

  @media ${props => props.theme.breakpoints.small} {
    flex-flow: ${props =>
      props.stackOnMobile && props.reverseOnMobile
        ? 'column-reverse'
        : props.stackOnMobile
        ? 'column'
        : props.reverseOnMobile
        ? 'row-reverse'
        : null};
  }
`;

const GridItem = styled.div`
  /* display: flex; */
  width: ${props => (1 / props.itemsPerRow) * 100}%;
  ${props => props.itemWidth && 'width: ' + props.itemWidth};
  flex: 0 0 auto;
  padding: 0 1.5em;
  margin: 1em auto 2em;
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: stretch;

  *:first-child {
    margin-top: 0;
  }

  /** Remove top spacing from the first row */
  ${({ index, itemsPerRow }) =>
    index < itemsPerRow &&
    css`
      margin-top: 0;
    `}

  /** Remove bottom spacing from the last row **/
  ${({ index, itemsPerRow }) =>
    index >= itemsPerRow &&
    css`
      margin-bottom: 1em;
    `}


    @media ${props => props.theme.breakpoints.small} {
      /* ${props =>
        props.itemWidthMobile
          ? `width: ${props.itemWidthMobile}}`
          : props.itemWidth
          ? `width: ${props.itemWidth}`
          : props.stackOnMobile
          ? '100%'
          : 'width: 50%;'}; */
      width: 100%;
    }


  > *:first-child {
    margin-top: 0;
  }

  > *:last-child {
    margin-bottom: 0;
  }
`;

const Grid = ({ fields, ...props }) => {
  const {
    collection,
    itemsPerRow,
    itemAlignment,
    containerWidth = 'slim',
    customItemWidth,
    showSeparator,
    stackOnMobile = true,
    reverseOnMobile,
    customItemWidthMobile,
  } = fields;

  const itemWidthArray = customItemWidth && customItemWidth.split(' ');
  const itemWidthMobileArray =
    customItemWidthMobile && customItemWidthMobile.split(' ');

  return (
    <GridBlock isLastItem={props.index + 1 === props.numberOfBlocks}>
      <Container width={containerWidth}>
        <GridContainer
          itemAlignment={itemAlignment}
          stackOnMobile={stackOnMobile}
          reverseOnMobile={reverseOnMobile}
        >
          {collection &&
            collection.length &&
            collection.map((gridItem, i) => {
              const { sys } = gridItem;
              let itemContentType = null;
              let contentId = i;
              let fields = gridItem;

              if (sys) {
                itemContentType =
                  sys.contentType &&
                  sys.contentType.sys &&
                  sys.contentType.sys.id;
                contentId = sys.id;
              } else if (gridItem.type) {
                /** This is a Netlify content structure */
                itemContentType = gridItem.type;
                fields = {
                  fields: {
                    ...gridItem,
                  },
                };
              }

              if (!itemContentType) {
                return false;
              }

              const ContentBlock = contentBlocks[itemContentType];

              return (
                <GridItem
                  gridGap={3}
                  itemsPerRow={itemsPerRow}
                  key={contentId}
                  index={i}
                  numberOfItems={collection.length}
                  itemWidth={itemWidthArray && itemWidthArray[i]}
                  itemWidthMobile={
                    itemWidthMobileArray && itemWidthMobileArray[i]
                  }
                  showSeparator={showSeparator}
                  stackOnMobile={stackOnMobile}
                >
                  {ContentBlock && (
                    <ContentBlock
                      {...fields}
                      {...gridItem}
                      fullWidth
                      isNested
                      index={i}
                      parentElement="grid"
                      mediaGridWidth={itemContentType === 'media' && '130%'}
                      mediaGridAlign={
                        itemContentType === 'media' && i % itemsPerRow === 0
                          ? 'right'
                          : itemContentType === 'media' &&
                            (i - 1) % itemsPerRow === 0
                          ? 'left'
                          : null
                      }
                    />
                  )}
                </GridItem>
              );
            })}
        </GridContainer>
      </Container>
    </GridBlock>
  );
};

Grid.propTypes = {
  fields: PropTypes.shape({
    adminTitle: PropTypes.string,
    collection: PropTypes.array,
    displayTheTitle: PropTypes.bool,
    itemsPerRow: PropTypes.number,
    itemAlignment: PropTypes.string,
  }).isRequired,
};

Grid.defaultProps = {
  fields: {
    adminTitle: '',
    collection: [],
    displayTheTitle: false,
    itemsPerRow: 3,
    itemAlignment: 'Top',
    fullWidth: false,
    showSeparator: false,
  },
};

export default Grid;
