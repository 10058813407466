import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import MarkdownParser from '../MarkdownParser';
import Container from '../Container';
import { Paragraph, Span } from '../Text';
import { ReactComponent as BlobSvg } from '../../svg/quote-blob.svg';

const CalloutBlock = styled.div`
  text-align: left;
  font-size: 2rem;
  font-family: ${({ theme }) => theme.fonts.primary};
  font-style: italic;
  font-weight: 400;
  line-height: 1.4;
  color: ${({ theme, textColor }) =>
    theme.colors[textColor] || theme.colors.pink};

  /** May not need this: */
  p,
  ${Paragraph} {
    font-family: inherit;
    font-size: inherit;
    font-weight: inherit;
    line-height: inherit;
  }

  @media ${props => props.theme.breakpoints.medium} {
    font-size: 1.25em;
  }
`;

const Attribution = styled(Paragraph)`
  && {
    text-align: right;
    font-size: 3.6rem;
    font-family: ${({ theme }) => theme.fonts.primary};
    color: ${({ theme }) => theme.colors.black};
    font-size: 1.8rem;
    font-weight: 700;
  }
`;

const StyledContainer = styled(Container)`
  padding: 0.5em 1em;
  position: relative;

  @media ${props => props.theme.breakpoints.medium} {
    padding: 0.5em;
  }
`;

const Blob = styled(({ bgColor, ...rest }) => <BlobSvg {...rest} />)`
  position: absolute;
  height: 90%;
  width: 80%;
  top: 50%;
  transform: translateY(-50%);
  z-index: -1;
  color: ${({ theme, bgColor }) =>
    theme.colors[bgColor] || theme.colors.greenHighlight};
`;

/** The most basic type of block we can render */
const Callout = props => {
  const { fields } = props;
  const { content, attribution, textColor, backgroundColor } = fields;

  return content ? (
    <CalloutBlock textColor={textColor}>
      <StyledContainer size="slim">
        <Blob bgColor={backgroundColor} />
        <MarkdownParser>{content}</MarkdownParser>
        {attribution && (
          <Attribution>
            &mdash;&nbsp;
            <MarkdownParser
              overrides={{
                p: Span,
              }}
            >
              {attribution}
            </MarkdownParser>
          </Attribution>
        )}
      </StyledContainer>
    </CalloutBlock>
  ) : null;
};

Callout.propTypes = {
  fields: PropTypes.shape({
    /** Body is the only required child of fields */
    content: PropTypes.string.isRequired,
    attribution: PropTypes.string,
    textColor: PropTypes.string,
    backgroundColor: PropTypes.string,
  }).isRequired,
};

export default Callout;
