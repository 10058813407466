import { createGlobalStyle } from 'styled-components/macro';
import { normalize } from 'polished';
import '../config/fontFace.css';

/**
 * This is a one-time-use method to add global styles.
 *
 * Use for things like body and html styles, and anything that else can't fit as
 * a react component
 */
export default createGlobalStyle`
  ${normalize()}

  * {
    box-sizing: border-box;

    ::selection {
      background: ${({ theme }) => theme.colors.alert};
    }
  }

  html,
  body {
    height: 100%;
    width: 100%;
    /* overflow-x: hidden; */
    /* overflow-y: auto; */
  }

  html {
    /** Allows us to easily translate px to rem, e.g. 1.8rem = 18px */
    font-size: 62.5%;

    @media ${props => props.theme.breakpoints.small} {
      font-size: 50%;
    }
  }

  body {
    /** Brand-standard defaults, matches default body style */
    font-family: ${props => props.theme.fonts.primary};
    font-weight: 300;
    font-style: normal;
    font-size: 1.8em;
    line-height: 1.6;
    color: ${props => props.theme.colors.black};

    @media ${props => props.theme.breakpoints.small} {
      overflow-x: hidden;
    }
  }

  strong {
    font-weight: 700;
  }

  em {
    font-style: italic;
  }
`;
