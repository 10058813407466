/** A place to put miscellaneous config options */
export default {
  shouldUseRemoteContent: false,
  contentful: {
    space: '',
    accessToken: '',
  },
  isPaged: false,
  isHeaderUnderFirstBlock: false,
  /** 'dot' or 'full' */
  nav: 'dot',
  twitterHandle: 'enboarder',
  hubpostFormId: null,
  hubspotFormPortalId: null,
};
