// import React from 'react';
// import Image from './Image';
import styled from 'styled-components/macro';
import { ReactComponent as Logo } from '../svg/logo.svg';
// import logo from '../svg/logo.svg';

/**
 * The logo, which usually just goes in the header. This could easily go inside
 * the Header component, but this keeps it a little easier to read
 */

// export default styled.img``;

/** SVGs are already compiled as react components, so just do this */

export default styled(Logo)`
  display: block;
  font-size: 1rem;
  width: 100%;
  max-width: 16em;
  /* height: auto; */

  @media ${props => props.theme.breakpoints.medium} {
    width: 14em;
  }
`;

/** If we wanted to export an actual image we could do this: */
// export default () => <Image src={logo} alt="Logo" />;
