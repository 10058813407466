import styled, { css } from 'styled-components/macro';

const ParagraphStyles = css`
  font-family: ${({ theme }) => theme.fonts.primary};
  margin: 0 0 1.6em 0;
  font-weight: 400;
  font-size: 1.8rem;
  line-height: 1.65;
`;

export const Paragraph = styled.p`
  ${ParagraphStyles}
`;

export const Span = styled.span`
  /* color: ${props =>
    props.color &&
    props.theme.colors[props.color] &&
    props.theme.colors[props.color]}; */
`;

const IntroStyles = css`
  font-size: 2.5rem;
  line-height: 1.65;
`;

export const Intro = styled(Paragraph)`
  ${IntroStyles}
`;

export const IntroStyle = styled.span`
  ${IntroStyles}
`;

export const Eyebrow = styled(Paragraph)`
  font-weight: 700;
  font-size: 1.4rem;
  line-height: 1.55;
  text-transform: uppercase;
`;

export const Strong = styled.strong`
  font-weight: 700;
`;

export const Italic = styled.em`
  font-style: italic;
  font-weight: 300;

  ${Strong} & {
    font-weight: 900;
  }
`;

export const ListItem = styled.li`
  margin: 0 0 0.5rem 0;
`;

export const UL = styled.ul`
  margin: 0 0 2rem;
  padding: 0 0 0 1.2em;
  /* color: ${props =>
    props.color && props.theme.colors[props.color]
      ? props.theme.colors[props.color]
      : 'inherit'}; */
  ${ParagraphStyles}

  * {
    color: inherit;
  }

  ul,
  ol {
    margin-top: 0.25em;
    padding-left: 2em;
  }

  ${ListItem} & {
    margin-bottom: 0;
    list-style-type: decimal;
  }

  ${ListItem} {
    list-style: none;
    position: relative;

    &:before {
      content: '—';
      position: absolute;
      left: -1.1em;
      color: ${({ theme }) => theme.colors.alert};
    }
  }
`;

export const OL = styled.ol`
  margin: 0 0 2rem;
  padding: 0 0 0 1.5em;
  list-style-type: decimal;
  /* color: ${props =>
    props.color && props.theme.colors[props.color]
      ? props.theme.colors[props.color]
      : 'inherit'}; */
  ${ParagraphStyles}

  * {
    color: inherit;
  }

  ul,
  ol {
    padding-left: 2em;
  }

  ${ListItem} & {
    margin-bottom: 0;
    list-style-type: decimal;
  }
`;

export const LI = styled.li`
  margin-bottom: 1em;

  li {
    margin-bottom: 0.15em;
  }

  &:last-child {
    margin-bottom: 0;
  }
`;

export const Cite = styled.cite``;

export const Blockquote = styled.blockquote`
  margin: 4em auto;

  ${Paragraph} {
    color: ${({ theme }) => theme.colors.primary};
    font-size: 1.2em;
    font-weight: 100;
    text-align: center;
  }

  ${UL},
  ${OL} {
    margin: 1rem auto;
    width: 50%;
  }

  ${ListItem} {
    color: ${({ theme }) => theme.colors.primary};
    font-size: 1em;
    font-weight: 100;
  }
`;
