import React from 'react';
import styled, { css } from 'styled-components/macro';

const BackgroundImageBlock = styled.div`
  position: absolute;
  z-index: -1;
  height: 20em;
  width: 20em;
  top: 50%;
  ${({ position }) => position}: -3%;
  background-image: url(${({ image }) => image});
  background-size: contain;
  background-repeat: no-repeat;
  transform: translateY(-50%);
  transform-origin: center;

  ${({ customStyles }) =>
    !!customStyles &&
    css`
      ${customStyles.code}
    `}
`;

const BackgroundImage = props => {
  const { customStyles, image, position } = props;

  return (
    <BackgroundImageBlock
      customStyles={customStyles}
      image={image}
      position={position}
    />
  );
};

export default BackgroundImage;
