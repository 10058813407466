import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import { transparentize, transitions } from 'polished';
import Icon from '../Icon';
import { useSpring, animated } from 'react-spring';
import useMeasure from './useMeasure';
import { H4 } from '../Heading';
import { ReactComponent as Plus } from '../../svg/plus.svg';

import Container from '../Container';
import contentBlocks from '../../config/contentBlocks';

const Collapsible = styled.div`
  margin: 2em auto;

  .collapsible {
    .collapsible-heading {
      background: ${props => props.theme.colors.white};

      &:hover {
        path {
          fill: ${props => props.theme.colors.backgroundHover};
        }
      }
    }
  }
`;

const CollapsibleContainer = styled.div`
  padding: 0;
  border: ${({ theme }) => `1px solid ${theme.colors.greyLight}`};
  border-radius: 1.8em;
  background: ${props => props.theme.colors.white};
`;

const CollapsibleHeading = styled.button`
  appearance: none;
  background-color: inherit;
  width: 100%;
  display: inline-flex;
  border-radius: 1.8em;
  justify-content: space-between;
  align-items: center;
  // padding: 0.6em 1em;
  padding: 1.08rem 1.8rem;
  cursor: pointer;
  user-select: none;
  position: relative;
  z-index: 2;
  border: 1px solid ${({ theme }) => theme.colors.grey};
  box-shadow: ${({ theme }) =>
    `1px 4px 17px -6px ${transparentize(0.85, theme.colors.black)}`};

  &:active,
  &:focus {
    width: calc(100% + 0.2rem);
    outline: none;
    border-color: ${props => props.theme.colors.purple};
    margin: -1px;
    padding: 1.18rem 1.9rem;
  }

  &:hover {
    path {
      fill: ${props => props.theme.colors.yellowLight};
    }
  }
`;

const Title = styled(H4)`
  margin: 0;
`;

const CollapsedText = animated(styled.div`
  background: transparent;
  overflow: hidden;
  user-select: none;
  position: relative;
  z-index: 1;

  /* h2,
  h3,
  h4,
  h5 {
    color: ${props => props.theme.colors.backgroundHover};
  } */
`);

const CollapsedPadding = styled.div`
  padding: 0 2em;
`;

const CollapsibleIcon = styled(Icon)`
  margin-right: 1rem;
  transform: ${({ opened }) => opened && `rotate(180deg)`};
  color: ${({ theme }) => theme.colors.greenHighlightPastel};
  ${({ theme }) => transitions(['color'], theme.animations.ease)}

  ${CollapsibleHeading}:hover & {
    color: ${({ theme }) => theme.colors.purple};
  }

  .updown {
    opacity: ${({ opened }) => (opened ? 0 : 1)};
  }
`;

const CollapsibleContent = props => {
  const [open, toggle] = useState(false);
  const [bind /*, { height }*/] = useMeasure();

  const spring = useSpring({
    opacity: open ? 1 : 0,
    /**
     * maxHeight is being used here temporarily until we can figure out how to
     * use useMeasure to get the height of a hidden element and animate the
     * height instead. Overall, this animation could be improved with some work.
     */
    maxHeight: open ? 20000 : 0,
    x: open ? 0 : -20,
  });

  return (
    <Collapsible className="collapsible">
      <Container>
        <CollapsibleContainer>
          <CollapsibleHeading
            className="collapsible-heading"
            onClick={() => toggle(!open)}
          >
            <Title>{props.fields.heading}</Title>
            <div>
              <CollapsibleIcon
                // style={{
                //   transform: open ? 'rotate(180deg)' : 'none',
                // }}
                width={'2rem'}
                height={'2rem'}
                opened={open ? 1 : 0}
                icon={Plus}
              />
            </div>
          </CollapsibleHeading>
          <CollapsedText
            {...bind}
            style={{
              height: open ? 'auto' : 0,
              opacity: spring.opacity,
              maxHeight: spring.maxHeight,
              transform: spring.x.interpolate(x => `translate3d(0,${x}px,0)`),
            }}
          >
            <CollapsedPadding>
              {props.fields.collection &&
                props.fields.collection.length &&
                props.fields.collection.map((contentBlock, i) => {
                  const { sys } = contentBlock;
                  let itemContentType = null;
                  let contentId = 1;
                  let fields = contentBlock;

                  if (sys) {
                    itemContentType =
                      sys.contentType &&
                      sys.contentType.sys &&
                      sys.contentType.sys.id;
                    contentId = sys.id;
                  } else if (contentBlock.type) {
                    /** This is a Netlify content structure */
                    itemContentType = contentBlock.type;
                    contentId = i;
                    fields = {
                      fields: {
                        ...contentBlock,
                      },
                    };
                  }

                  if (!itemContentType) {
                    return false;
                  }

                  const ContentBlock = contentBlocks[itemContentType];

                  return !!ContentBlock ? (
                    <div key={contentId}>
                      <ContentBlock {...fields} {...contentBlock} />
                    </div>
                  ) : null;
                })}
            </CollapsedPadding>
          </CollapsedText>
        </CollapsibleContainer>
      </Container>
    </Collapsible>
  );
};

CollapsibleContent.propTypes = {
  /** Text to use for the heading */
  heading: PropTypes.string.isRequired,
  /**
   * Whether to chain this collapsible conent with others around it and close
   * all of them except the one just opened.
   * TODO: This is not enabled yet
   */
  accordion: PropTypes.bool,
};

CollapsibleContent.defaultProps = {
  heading: '',
  accordion: false,
};

export default CollapsibleContent;
