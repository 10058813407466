import { createHashHistory } from 'history';
import { createStore, compose, applyMiddleware } from 'redux';
import thunkMiddleware from 'redux-thunk';
import { routerMiddleware } from 'connected-react-router';
import { createLogger } from 'redux-logger';
import createRootReducer from './reducers';
import initialState from './initialState';
import packageJson from '../../package.json';

const homePath = packageJson.homepage ? packageJson.homepage : null;

/**
 * Check the homepage key in package.json before applying them to the router. We
 * can't fix the error, even though it's simple, bc CRA will use the same
 * config when building, so we need to fix the error at the source
 */
if (!homePath || homePath !== '.') {
  throw new Error(
    `"homepage" in package.json must exist and be set to "." or the build will not be served with relative asset paths.`
  );
}

export const history = createHashHistory();
const middleware = [routerMiddleware(history), thunkMiddleware];

/** Allow production lightpapers to be debugged using ?debug=true */
const shouldDebug =
  new URLSearchParams(new URL(window.location).search).get('debug') === 'true';

if (
  // process.env.NODE_ENV === 'development' ||
  shouldDebug
) {
  middleware.push(
    createLogger({
      // diff: true,
    })
  );
}

const store = createStore(
  createRootReducer(history),
  initialState,
  compose(applyMiddleware(...middleware))
);

export default store;
