import React from 'react';
import styled, { css } from 'styled-components/macro';
import Container from '../Container';
import Counter from '../Counter';
import MarkdownParser from '../MarkdownParser';
import { ChartTitle, CaptionBody } from '../ChartElements';

const Block = styled.div`
  margin: 3em auto;
  padding-bottom: ${props => (props.isLastItem ? '2em' : 0)};
  max-width: 16em;
  /* max-width: 30rem; */

  @media ${props => props.theme.breakpoints.small} {
    margin-left: auto;
    margin-right: auto;
    text-align: center;
  }
`;

const Layout = styled.div`
  ${props =>
    props.horizontal &&
    css`
      display: flex;
      align-items: center;
      justify-content: space-between;
    `}
`;

const CounterContainer = styled.div`
  ${props => props.horizontal && `width: 15rem`}
`;

const ContentContainer = styled.div`
  ${props => props.horizontal && `width: calc(100% - 15rem)`};
  margin: 0.5em auto;
  text-align: center;
`;

const CounterBlock = props => {
  const {
    fields,
    number,
    content,
    prefix,
    suffix,
    horizontalLayout,
    // ...rest
  } = props;
  const { adminTitle, displayTheTitle } = fields;

  /**
   * If we want to also use this block inline with markdown, which we do, then
   * we need to determine if this component is getting the data from a fields
   * object or directly through individual props.
   */
  const num = number || fields.number;
  const pre = prefix || fields.prefix;
  const suf = suffix || fields.suffix;
  const cont = content || fields.content;
  const horizontal = horizontalLayout || fields.horizontalLayout;

  return (
    <Block isLastItem={props.index + 1 === props.numberOfBlocks}>
      <Container>
        <Layout horizontal={horizontal ? 1 : 0}>
          {adminTitle && displayTheTitle && (
            <ChartTitle>{adminTitle}</ChartTitle>
          )}
          {/* {iconSrc && <Icon src={iconSrc} alt={iconAlt}></Icon>} */}
          <CounterContainer horizontal={horizontal ? 1 : 0}>
            <Counter
              end={num}
              prefix={pre}
              suffix={suf}
              index={props.index}
            ></Counter>
          </CounterContainer>
          <ContentContainer horizontal={horizontal ? 1 : 0}>
            {cont && (
              <MarkdownParser
                overrides={{
                  p: { component: CaptionBody },
                  // span: { component: Content },
                }}
              >
                {cont}
              </MarkdownParser>
            )}
          </ContentContainer>
        </Layout>
      </Container>
    </Block>
  );
};

CounterBlock.defaultProps = {
  fields: {},
  horizontalLayout: false,
};

export default CounterBlock;
