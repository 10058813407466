import React from 'react';
import styled from 'styled-components/macro';
import HubspotForm from '../HubspotForm';

const Block = styled.div`
  background: ${props => props.theme.colors.primary};
  border-radius: 0.3em;
  padding: 1.5em;
  max-width: 50rem;
  /* margin: 0 auto; */
`;

const HubspotFormBlock = ({ id, ...props }) => {
  return (
    <Block>
      <HubspotForm {...props} id={id || `hubspot-form-${props.formId}`} />
    </Block>
  );
};

export default HubspotFormBlock;
