import React, { useState } from 'react';
import styled from 'styled-components/macro';
import Waypoint from '../Waypoint';
import { useSpring, animated } from 'react-spring';
import Container from '../Container';
import { countDecimals } from '../Counter';

const Block = styled.div`
  margin: 2% 0;
  width: 100%;
  padding-bottom: ${props => (props.isLastItem ? '2em' : 0)};
`;

const Label = styled.span`
  display: block;
  margin: 0 0 0.5em;
  font-size: 1.6rem;
  line-height: 1.3;
  font-weight: 700;
  font-family: ${props => props.theme.fonts.sansSerif};
  color: ${props => props.theme.text || props.theme.colors.primary};
`;

const Icon = styled.img`
  width: 4.5em;
  height: auto;
  flex: 0 0 auto;
  margin-right: 2em;
`;

const ChartContainer = styled.div`
  position: relative;
  flex: 1 1 auto;
`;

const SvgContainer = styled.div`
  position: relative;
  height: 0;
  width: 100%;
  padding-bottom: ${props => (props.tall / props.wide) * 100}%;
`;

const Svg = styled.svg`
  position: absolute;
  width: 100%;
  height: 100%;
  overflow: visible;
`;

/** The bar that spans the full width of the SVG */
const Rect = styled.rect`
  fill: ${props =>
    props.theme.sentenceBarBackground || props.theme.colors.primary};
`;

/** The bar that displays the data value */
/**
 * HACK: Manually filter out the dataColor prop so styled-components doesn't
 * render it in the DOM
 */
const Path = styled(({ dataColor, ...props }) => {
  return <animated.path {...props} />;
})`
  stroke: ${props =>
    props.dataColor && props.dataColor.toLowerCase() === 'negative'
      ? props.theme.colors.orchid
      : props.theme.primary};
`;

const SvgText = styled(animated.text)`
  /** This is the data value percentage */
  ${'' /* transform: ${props => `translateX(${props.value}px)`}; */}
  font-family: ${props => props.theme.fonts.sansSerif};
  font-weight: 700;
  line-height: 1;
  color: ${props => props.theme.text || props.theme.colors.primary};
`;

const TextSuffix = styled.tspan`
  font-size: 0.5em;
`;

/** Renders a bar displaying a percentage with an optional label and icon */
const SentenceBar = ({ fields, ...props }) => {
  const [shouldAnimate, setAnimate] = useState(false);

  const { dataColor, dataValue, icon, label } = fields;

  const iconSrc =
    fields.icon &&
    fields.icon.fields &&
    fields.icon.fields.file &&
    fields.icon.fields.file.url;
  const iconAlt =
    fields.icon &&
    fields.icon.fields &&
    (fields.icon.fields.description || fields.icon.fields.title);

  const springProps = useSpring({
    /** Add a reverse count to easily change the stroke offset of the bar */
    from: { number: 0, numberReverse: dataValue },
    /**
     * There's no "pause" in react-spring currently, so set the start value as
     * the end too so it doesn't actually change.
     */
    to: {
      number: shouldAnimate ? dataValue : 0,
      numberReverse: shouldAnimate ? 0 : dataValue,
    },
    /** When we reset set it back to 0 immediately */
    immediate: !shouldAnimate,
    // config: { mass: 1, tension: 120, friction: 26 },
    config: { mass: 1, tension: 500, friction: 100 },
  });

  /** Format the number in the middle of the radial */
  const numberOfDecimals = countDecimals(dataValue);
  const formatNumber = value => (+value).toFixed(numberOfDecimals);

  /** Some dimensions for the SVG */
  const chartHeight = props.fullWidth ? 8 : 6;
  const chartWidth = 100;
  /** Include extra width in case the value is 100 and extends past the bar */
  const svgViewBox = `0 0 110 ${chartHeight}`;
  /** Center the path vertically and set the length of it to match the value */
  const svgPathD = `M0,${chartHeight / 2}h${dataValue}`;

  return (
    <Waypoint
      topOffset="0"
      bottomOffset="0"
      onEnter={() => setAnimate(true)}
      onLeave={() => setAnimate(false)}
    >
      <Block isLastItem={props.index + 1 === props.numberOfBlocks}>
        <Container>
          {icon && <Icon src={iconSrc} alt={iconAlt}></Icon>}
          <ChartContainer>
            {label && <Label>{label}</Label>}
            {/** Use wide and tall so these don't get rendered in the DOM */}
            <SvgContainer wide={chartWidth} tall={chartHeight}>
              <Svg
                x="0"
                y="0"
                viewBox={svgViewBox}
                width="110px"
                height={chartHeight + 'px'}
              >
                <Rect x="0" y="0" width={chartWidth} height={chartHeight} />
                <Path
                  stroke="black"
                  d={svgPathD}
                  fill="none"
                  strokeWidth={chartHeight}
                  strokeDasharray={dataValue}
                  strokeDashoffset={springProps.numberReverse}
                  dataColor={dataColor}
                />
                <SvgText
                  x="0"
                  dx={springProps.number.interpolate(x => x + 2)}
                  y={props.fullWidth ? 6.5 : 4.75}
                  textAnchor="left"
                  fill="currentColor"
                  fontSize={props.fullWidth ? '7px' : '5px'}
                >
                  <animated.tspan>
                    {springProps.number.interpolate(formatNumber)}
                  </animated.tspan>
                  <TextSuffix dy={props.fullWidth ? -2.5 : -1.75} dx="0">
                    %
                  </TextSuffix>
                </SvgText>
              </Svg>
            </SvgContainer>
          </ChartContainer>
        </Container>
      </Block>
    </Waypoint>
  );
};

SentenceBar.defaultProps = {
  fullWidth: false,
};

export default SentenceBar;
