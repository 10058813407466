import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import HeaderStyled from './HeaderStyled';
import HeaderWrapper from './HeaderWrapper';
import Logo from '../Logo';
// import LanguageSelect from '../LanguageSelect';

const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 1.6rem;
  padding: 0 2em;
  color: ${props =>
    props.isStuck ? props.theme.colors.primary : props.theme.colors.primary};
`;

/**
 * The header is built to be placed in one of two spots: 1) At the top of the
 * page, or 2) At the bottom of the top section.
 *
 * If it's at the top of the page, it should also be `position: absolute` so the
 * hero can "bleed" behind the header.
 *
 * If it's at the bottom we need to update the wrapper height when it becomes
 * sticky to avoid a jump in the page
 *
 * The actual "sticking" of the header is done by HeaderTrigger
 */
class HeaderPresentation extends React.PureComponent {
  static propTypes = {
    isStuck: PropTypes.bool.isRequired,
    isUnderFirstBlock: PropTypes.bool.isRequired,
  };

  constructor(props) {
    super(props);
    /**
     * We'll need to calculate the height of the header when it becomes sticky.
     * Not ideal but it's the only way we can prevent setting an explicit header
     * height
     */
    this.headerRef = React.createRef();
    this.headerWrapperRef = React.createRef();
  }

  componentDidUpdate() {
    /**
     * We only want to update the wrapper height if it was rendered under the
     * first section, which should be the only time it's not position fixed or
     * absolute
     */
    if (this.props.isStuck && this.props.isUnderFirstBlock) {
      /** Add the derived height as an inline style */
      this.headerWrapperRef.current.style.height = `${
        /** Get the current header height */
        this.headerRef.current.getBoundingClientRect().height
      }px`;
      return null;
    }
    return null;
  }

  render() {
    return (
      <HeaderWrapper ref={this.headerWrapperRef} isStuck={this.props.isStuck}>
        <HeaderStyled
          ref={this.headerRef}
          isStuck={this.props.isStuck}
          isUnderFirstBlock={this.props.isUnderFirstBlock}
        >
          <HeaderContainer wide isStuck={this.props.isStuck}>
            <Logo />
          </HeaderContainer>
        </HeaderStyled>
      </HeaderWrapper>
    );
  }
}

export default HeaderPresentation;
