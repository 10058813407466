import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import Waypoint from '../Waypoint';
import Container from '../Container';
import Image from '../Image';
import MarkdownParser from '../MarkdownParser';

const PictogramBlock = styled.div`
  margin: 2em auto;
  padding-bottom: ${props => (props.isLastItem ? '2em' : 0)};
`;

const StyledContainer = styled(Container)`
  display: ${props => (props.horizontal ? 'flex' : 'block')};
  text-align: ${props => (props.horizontal ? 'left' : 'center')};
  align-items: center;
`;

const PictogramImage = styled(Image)`
  display: inline-block;
  width: 12em;
  max-width: 100%;
  margin: 0 auto;
  ${props => props.horizontal && 'margin-right: 1em;'}
  opacity: ${props => (props.shouldAnimate ? '1' : '0.5')};
  transform: ${props => (props.shouldAnimate ? 'scale(1)' : 'scale(0.6)')};
  transition: all 0.4s ease-in-out;
`;

const PictogramIntro = styled.div`
  margin: 0;
`;

const Pictogram = ({ fields, ...rest }) => {
  const [shouldAnimate, setAnimate] = useState(false);
  const imageSrc =
    fields.image &&
    fields.image.fields &&
    fields.image.fields.file &&
    fields.image.fields.file.url;
  const imageAlt =
    fields.image &&
    fields.image.fields &&
    (fields.image.fields.description || fields.image.fields.title);
  const isHorizontal = fields.horizontalLayout;

  return (
    <Waypoint
      topOffset="0"
      bottomOffset="25%"
      onEnter={() => setAnimate(true)}
      onLeave={() => setAnimate(false)}
    >
      <PictogramBlock isLastItem={rest.index + 1 === rest.numberOfBlocks}>
        <StyledContainer horizontal={isHorizontal}>
          <PictogramImage
            src={imageSrc}
            alt={imageAlt}
            horizontal={isHorizontal}
            shouldAnimate={shouldAnimate}
          ></PictogramImage>
          <MarkdownParser
            overrides={{
              p: { component: PictogramIntro },
              Paragraph: { component: PictogramIntro },
              // span: { component: Intro },
              // Span: { component: Intro },
            }}
          >
            {fields.content}
          </MarkdownParser>
        </StyledContainer>
      </PictogramBlock>
    </Waypoint>
  );
};

Pictogram.propTypes = {
  fields: PropTypes.shape({
    image: PropTypes.shape({
      title: PropTypes.string,
      file: PropTypes.shape({
        url: PropTypes.string,
      }),
    }),
    content: PropTypes.string,
    horizontalLayout: PropTypes.bool,
  }).isRequired,
};

Pictogram.defaultProps = {};

export default Pictogram;
