import styled from 'styled-components/macro';

/** Set up default heading elements */

export const H1 = styled.h1`
  font-family: ${({ theme }) => theme.fonts.primary};
  margin: 0 0 4rem;
  color: ${({ theme }) => theme.colors.primary};
  font-weight: 800;
  font-size: 4.2rem;
  line-height: 1.2;

  @media ${({ theme }) => theme.breakpoints.small} {
    font-size: 3.4rem;
  }
`;

export const H2 = styled.h2`
  font-family: ${({ theme }) => theme.fonts.primary};
  margin: 0 0 2rem;
  color: ${({ theme }) => theme.colors.briangerine};
  font-weight: bold;
  font-size: 3.4rem;
  line-height: 1.3;

  @media ${({ theme }) => theme.breakpoints.small} {
    font-size: 2.8rem;
  }
`;

export const H3 = styled.h3`
  font-family: ${({ theme }) => theme.fonts.primary};
  margin: 0 0 2rem;
  color: ${({ theme }) => theme.colors.primary};
  font-weight: bold;
  font-size: 2.6rem;
  line-height: 1.4;

  @media ${({ theme }) => theme.breakpoints.small} {
    font-size: 2.4rem;
  }
`;

export const H4 = styled.h4`
  margin: 0 0 1rem;
  color: ${({ theme }) => theme.colors.primary};
  font-size: 2.4rem;
  line-height: 1.5;
  font-weight: 700;

  @media ${({ theme }) => theme.breakpoints.small} {
    font-size: 2.2rem;
  }
`;

export const H5 = styled.h5`
  margin: 0;
  color: ${({ theme }) => theme.colors.primary};
  font-size: 1rem;
  line-height: 1.6;
  font-weight: 700;
`;

export const H6 = styled.h6`
  margin: 0;
  color: ${({ theme }) => theme.colors.primary};
  font-size: 1rem;
  line-height: 1.6;
  font-weight: 400;
  font-style: italic;
`;
