import React, { useState, useEffect } from 'react';
import styled, { css } from 'styled-components/macro';
import C from '../Container';
// import Image from '../Image';
import MarkdownParser from '../MarkdownParser';
import { H1, H2 } from '../Heading';
import { transparentize } from 'polished';

const SectionTitleBlock = styled.div`
  margin: 0 0 2em;
  position: relative;
  margin-bottom: 6vw;
  padding: 4em 0 8vw;
  min-height: 30rem;

  ${({ bgImage }) =>
    !!bgImage &&
    css`
      background-image: url(${bgImage});
      background-size: 110%;
      background-repeat: no-repeat;
      background-position: top center;
    `}
`;

const Container = styled(C)``;

const Eyebrow = styled(H2)`
  margin: 0 auto;
  padding: 0.25em 0.75em;
  display: inline-block;
  background-color: ${({ theme }) => theme.colors.black};
  color: ${({ theme }) => theme.colors.yellow};
`;

const SectionTitleInner = styled.div`
  padding-bottom: 4em;
  color: ${({ theme }) => theme.colors.white};
  top: 0;
  left: 0;

  /* ${H1} {
    text-shadow: ${({ theme }) =>
      `1px 1px 9px ${transparentize(0.65, theme.colors.black)}`};
  } */
`;

// const HeroImage = styled(Image)`
//   width: 100%;
//   height: auto;
//   position: absolute;
//   top: 0;
//   z-index: -1;
// `;

const HeroSvgContainer = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: -1;

  svg {
    width: 100%;
    max-width: 100%;
    height: auto;
    min-height: 120%;
  }

  @media ${({ theme }) => theme.breakpoints.medium} {
    height: 130%;
  }

  @media ${({ theme }) => theme.breakpoints.small} {
    height: 140%;
  }
`;

const SectionTitle = props => {
  const { fields, ...rest } = props;
  const { hero, sectionTitle, eyebrow } = fields;

  const [heroSvg, setHeroSvg] = useState('');

  useEffect(() => {
    const isSvg = fileName => fileName && fileName.match(/\.svg/);
    async function fetchData() {
      await fetch(hero)
        .then(response => response.text())
        .then(body => setHeroSvg(body));
    }
    hero && isSvg(hero) && fetchData();
  }, [hero]);

  return (
    <SectionTitleBlock
      bgImage={!heroSvg && hero}
      isLastItem={rest.index + 1 === rest.numberOfBlocks}
    >
      {/* <HeroImage src={hero} /> */}
      {!!heroSvg && (
        <HeroSvgContainer dangerouslySetInnerHTML={{ __html: heroSvg }} />
      )}
      <Container>
        <SectionTitleInner>
          {sectionTitle && <MarkdownParser>{sectionTitle}</MarkdownParser>}
          {eyebrow && <Eyebrow>{eyebrow}</Eyebrow>}
        </SectionTitleInner>
      </Container>
    </SectionTitleBlock>
  );
};

SectionTitle.defaultProps = {
  sectionTitle: null,
  image: null,
};

export default SectionTitle;
