import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components/macro';
import { transitions } from 'polished';
import { useSpring, animated } from 'react-spring';
import Waypoint from './Waypoint';

const CounterTransitionStyles = css`
  opacity: ${({ shouldAnimate }) => (shouldAnimate ? 1 : 0)};
  ${({ theme }) => transitions(['opacity'], theme.animations.ease)}
`;

const CounterStyled = styled.div`
  text-align: center;
  color: ${({ theme, index }) => theme.charts[index % theme.charts.length]};
`;

const Value = styled(({ shouldAnimate, ...rest }) => (
  <animated.span {...rest} />
))`
  font-size: 8.4rem;
  line-height: 1;
  ${CounterTransitionStyles}

  @media ${({ theme }) => theme.breakpoints.medium} {
    font-size: 6.5rem;
  }

  @media ${({ theme }) => theme.breakpoints.small} {
    font-size: 4rem;
  }
`;

const Prefix = styled(({ shouldAnimate, ...rest }) => (
  <animated.span {...rest} />
))`
  font-size: 8rem;
  line-height: 1;
  ${CounterTransitionStyles}

  @media ${({ theme }) => theme.breakpoints.medium} {
    font-size: 6rem;
  }

  @media ${({ theme }) => theme.breakpoints.small} {
    font-size: 4rem;
  }
`;

const Suffix = styled(({ shouldAnimate, ...rest }) => (
  <animated.span {...rest} />
))`
  font-size: 8.4rem;
  line-height: 1;
  ${CounterTransitionStyles}

  @media ${({ theme }) => theme.breakpoints.medium} {
    font-size: 6.5rem;
  }
`;

/**
 * Count the number of decimal places in a number, even if those decimals are 0.
 *
 * @param {number} value The number to check
 * @returns {number} The number of decimal places, or 0
 */
export const countDecimals = value => {
  const [, decimals] = value.toString().split('.');
  return decimals ? decimals.length : 0;
};

/** Counting logic */
export const useCounter = (start, endValue, shouldAnimate = true, locale) => {
  let end = endValue;
  if (typeof end === 'string') {
    end = parseFloat(endValue);
  }

  const springProps = useSpring({
    from: { number: start },
    /**
     * There's no "pause" in react-spring currently, so set the start value as
     * the end too so it doesn't actually change
     */
    to: { number: shouldAnimate ? end : start },
    config: { mass: 1, tension: 500, friction: 100 },
  });

  /** Get the larger number of decimals from the two values */
  const numberOfDecimals = Math.max(countDecimals(start), countDecimals(end));

  return springProps.number.interpolate(value => {
    const returnNumber = parseFloat(value.toFixed(numberOfDecimals));
    const returnNumberFormatted = returnNumber.toLocaleString(locale);

    return returnNumberFormatted;
  });
};

/**
 * Animate a number from one value to the other. Allows for surrounding
 * content and animating decimal places by getting the highest number of decimal
 * places from the starting and ending values
 */
const Counter = props => {
  const [shouldAnimate, setAnimate] = useState(false);

  const number = useCounter(props.start, props.end, shouldAnimate);

  return (
    <Waypoint
      topOffset="0"
      bottomOffset="25%"
      onEnter={() => setAnimate(true)}
      onLeave={() => setAnimate(false)}
    >
      <CounterStyled ref={props.innerRef} index={props.index}>
        {props.prefix && (
          <Prefix shouldAnimate={shouldAnimate}>{props.prefix}</Prefix>
        )}
        {/** So styled-components can override this */}
        <Value className={props.className} shouldAnimate={shouldAnimate}>
          {number}
        </Value>
        {props.suffix && (
          <Suffix
            // as={props.suffix === '%' ? 'sup' : 'span'}
            shouldAnimate={shouldAnimate}
          >
            {props.suffix}
          </Suffix>
        )}
      </CounterStyled>
    </Waypoint>
  );
};

Counter.propTypes = {
  /** Values should be strings to avoid some number animation issues */
  start: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  end: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  prefix: PropTypes.string,
  suffix: PropTypes.string,
  index: PropTypes.number,
};

Counter.defaultProps = {
  start: 0,
  prefix: '',
  suffix: '',
  index: 0,
};

export default Counter;
