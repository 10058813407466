import React from 'react';
import styled from 'styled-components/macro';
import Markdown from 'markdown-to-jsx';
import Container from '../Container';

const HTMLBlock = styled.div`
  /** Targeting YouTube embeds: */
  iframe {
    width: 100%;
    height: 30em;

    @media ${({ theme }) => theme.breakpoints.medium} {
      height: 15em;
    }

    @media ${({ theme }) => theme.breakpoints.small} {
      height: 12em;
    }
  }
`;

/** The most basic type of block we can render */
const HTML = ({ fields, ...rest }) => {
  const { html } = fields;
  const { code = '' } = html;

  // return <Container dangerouslySetInnerHTML={{ __html: code }} />;
  return (
    <HTMLBlock>
      <Container>
        <Markdown>{code}</Markdown>
      </Container>
    </HTMLBlock>
  );
};

export default HTML;
