import React, { useEffect, useRef } from 'react';
import styled from 'styled-components/macro';
import { transitions } from 'polished';
import { ButtonStyles } from './Links';

const FormContainer = styled.div`
  label {
    display: block;
    color: ${({ theme }) => theme.colors.black};
    font-weight: 600;
    font-size: 1.2rem;
    line-height: 1.4;
    margin-bottom: 0.25em;
  }

  input,
  select,
  textarea {
    font-size: 1.8rem;
    line-height: 1.5;
    font-family: ${props => props.theme.fonts.primary};
    background: ${({ theme }) => theme.colors.white};
    color: ${({ theme }) => theme.colors.black};
    padding: 0.5em;
    width: 100%;
    border: ${({ theme }) => `1px solid ${theme.colors.greyLight}`};
    margin-bottom: 1em;
    ${({ theme }) => transitions(['border-color'], theme.animations.ease)};
    border-radius: 7px;

    &:active,
    &:focus {
      outline: none;
      border-color: ${({ theme }) => theme.colors.green};
    }

    &.error {
      border-color: ${({ theme }) => theme.colors.red};
    }
  }

  textarea {
    resize: vertical;
  }

  input[type='submit'] {
    ${ButtonStyles};

    display: block !important;
    width: auto;
    font-weight: 700;
    padding: 1rem 3rem;
    margin-top: 1em;
    cursor: pointer;
    ${({ theme }) =>
      transitions(['color', 'background', 'transform'], theme.animations.ease)}

    &:hover {
      background-position: left bottom;
    }
  }

  ul.inputs-list {
    padding: 0;
    list-style: none;

    li {
      label {
        display: flex;
        align-content: flex-start;
        justify-content: flex-start;
        flex-direction: row;
      }

      &.hs-form-booleancheckbox {
        input {
          width: auto;
          margin-right: 0.5em;
        }
      }
    }
  }

  .submitted-message,
  .legal-consent-container {
    color: ${({ theme }) => theme.colors.white};

    a {
      color: ${({ theme }) => theme.colors.white};
    }
  }

  .legal-consent-container {
    font-size: 0.8em;
  }

  .hs-error-msgs {
    margin: -0.8em 0 1em;
    padding: 0;
    list-style: none;

    label {
      color: ${({ theme }) => theme.colors.red};
    }
  }
`;

const HubspotForm = ({ formId, portalId, id, ...props }) => {
  const didLoad = useRef(false);
  /**
   * Custom-load the external form script so we can track when it loads or fails
   * and make any customizations needed when it does
   */
  useEffect(() => {
    if (window.hbspt && portalId && formId && id && !didLoad.current) {
      /** Quick fix to prevent the form loading many times */
      didLoad.current = true;
      window.hbspt.forms.create({
        region: 'na1',
        portalId: portalId,
        formId: formId,
        target: `#${id}`,
        ...props,
      });
    }
  }, [formId, id, portalId, props]);

  return <FormContainer id={id} className="hubspot-container"></FormContainer>;
};

export default HubspotForm;
