// import React from 'react';
// import styled from 'styled-components/macro';
// import { ReactComponent as Arrow } from '../svg/arrow-down.svg';
// import AnimateSvg from '../utilities/animateSvg';

// const StyledArrow = styled(Arrow)`
//   display: block;
//   color: ${props => props.theme.colors.alert};
//   height: 5em;
//   margin: 1em auto;
// `;

const Divider = ({ type, height, ...props }) => {
  if (type === 'arrow')
    // return <AnimateSvg svg={StyledArrow} type="image/svg+xml" />;
    return null;
};

Divider.defaultProps = {
  type: 'arrow',
  height: '1em',
};

export default Divider;
