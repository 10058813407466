import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import { useTrail, animated } from 'react-spring';
import Waypoint from './Waypoint';

const FlutteredList = styled.ul`
  /* list-style: none; */
  margin: 0 auto 2em;
  padding: 0 0 0 2em;
`;

const FlutteredItem = styled(animated.div)`
  li {
    /** Hacky specificity override: */
    &&,
    &:last-child {
      list-style: none;
      position: relative;
      font-size: 2rem;
      font-weight: 600;
      margin: 0 auto 0.5em;
    }

    * {
      font-size: inherit !important;
      margin: 0 !important;
    }

    &:before {
      content: '';
      background-image: url('/images/flutter-star.png');
      height: 1em;
      width: 1em;
      background-repeat: no-repeat;
      background-position: center center;
      background-size: contain;
      position: absolute;
      left: -1.6em;
      top: 0.2em;
    }
  }
`;

/**
 * Animate an ordered or unordered list using React Spring.
 * See https://codesandbox.io/embed/zn2q57vn13 as a reference on which this was
 * based.
 */
const Flutter = props => {
  const [shouldAnimate, setAnimate] = useState(false);
  const { children } = props;

  // const children = props.children[0].props.children.map(
  //   x => x.props.children[0]
  // );
  // const listType = props.children[0].type;

  // const children = props.children[0].props.children;
  // console.log(props.children[0].props.children);

  const trail = useTrail(children.length, {
    config: { mass: 5, tension: 2000, friction: 200 },
    opacity: shouldAnimate ? 1 : 0,
    x: shouldAnimate ? 0 : 20,
    from: { opacity: 0, x: props.direction === 'down' ? -20 : 20 },
  });

  return (
    <>
      {/** Don't wrap the Flutter in the waypoint bc there's some ref issues */}
      <Waypoint
        topOffset="30%"
        bottomOffset="30%"
        onEnter={() => (!shouldAnimate ? setAnimate(true) : false)}
      />
      {/**
       * TODO: Need to find a way to use the listType to set the styled component as
       * either a ul or ol. It is explicity set as a ul for now.
       */}
      <FlutteredList ref={props.innerRef}>
        {trail.map(({ x, ...rest }, index) => (
          <FlutteredItem
            key={Math.random() + children[index]}
            style={{
              ...rest,
              transform: x.interpolate(x => `translate3d(0,${x}px,0)`),
            }}
          >
            {children[index]}
          </FlutteredItem>
        ))}
      </FlutteredList>
    </>
  );
};

Flutter.propTypes = {
  children: PropTypes.node.isRequired,
  direction: PropTypes.string,
};

Flutter.defaultProps = {
  direction: 'down',
};

export default Flutter;
