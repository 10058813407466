import React, { useState, useEffect, useRef } from 'react';
import { NavLink } from 'react-router-dom';
import styled, { css } from 'styled-components/macro';
import Waypoint from '../Waypoint';

const Wrapepr = styled.div`
  /** Flex lets the nav to be scrollable if the window isn't tall enough */
  display: flex;
  flex-direction: column;
  position: ${props => (props.isStuck ? 'fixed' : 'absolute')};
  top: ${props => (props.isStuck ? '0' : '100%')};
  bottom: ${props => (props.isStuck ? '0' : 'auto')};
  /** This needs to be a higher z-index than the header */
  z-index: 2000;
  ${({ isOpen }) =>
    isOpen &&
    css`
      background: black;
      height: auto;
      width: 20em;
    `};
  ${({ isStuck }) =>
    !isStuck &&
    css`
      /** When not stuck we want it to look full height */
      min-height: 100vh;
    `};
`;

const Nav = styled.nav`
  display: ${props => (props.isOpen ? 'block' : 'none')};
  flex: 1;
  overflow: auto;
`;

/** Ties to react-routes active NavLink class */
const activeClassName = 'active-section';

const NavItem = styled(NavLink).attrs(props => ({
  activeClassName,
  exact: true,
}))`
  display: block;
  color: white;
  padding: 2em;
  border-bottom: 1px solid white;

  &.${activeClassName} {
    color: blue;
  }
`;

const Navicon = styled.span`
  flex: 0 0 auto;
  color: gray;
`;

const FullNavigation = ({ menuItems }) => {
  const [isStuck, setStuck] = useState(false);
  const [isOpen, toggleMenu] = useState(false);
  /** Used for determining nav interaction while it's open */
  const navRef = useRef(null);

  /** Listener to close the nav if anywhere outside the open nav is clicked */
  useEffect(() => {
    const windowClick = window.addEventListener(
      'click',
      event => {
        /** Only do this if the nav is open */
        if (isOpen) {
          event.stopPropagation();

          /**
           * Search up the DOM to check if the element is the nav or a child of
           * the nav
           */
          let targetElement = event.target;
          do {
            if (targetElement === navRef.current) {
              /** This is inside the nav */
              return false;
            }
            targetElement = targetElement.parentNode;
          } while (targetElement);

          /** If nothing matched, we're outside the nav */
          return toggleMenu(false);
        }
      },
      false
    );

    /** Provide a final clean-up function between renders */
    return () => {
      window.removeEventListener('click', windowClick);
    };
  });

  return (
    <>
      <Waypoint
        onPositionChange={event => setStuck(event.currentPosition === 'above')}
      />
      <Wrapepr ref={navRef} isStuck={isStuck} isOpen={isOpen}>
        <Navicon onClick={() => toggleMenu(!isOpen)}>Menu</Navicon>
        <Nav isOpen={isOpen}>
          {menuItems.map((item, index) => (
            <NavItem
              index={index}
              key={`menu-${item.id}`}
              to={item.path}
              /** When an item is clicked we want to close the nav */
              onClick={() => toggleMenu(false)}
            >
              {item.title}
            </NavItem>
          ))}
        </Nav>
      </Wrapepr>
    </>
  );
};

export default FullNavigation;
