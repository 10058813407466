import React from 'react';
import styled from 'styled-components/macro';
import Container from '../Container';
import { TwitterTweetEmbed } from 'react-twitter-embed';

const TweetContainer = styled(Container)`
  margin: 2em auto 4em;

  .twitter-tweet {
    margin-left: auto;
    margin-right: auto;
  }
`;

/** The most basic type of block we can render */
const HTMLBlock = ({ fields }) => {
  const { tweetId } = fields;

  return (
    <TweetContainer>
      <TwitterTweetEmbed tweetId={`${tweetId}`} />
    </TweetContainer>
  );
};

export default HTMLBlock;
