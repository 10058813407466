/** Define the shape of the app's initial state */
export default {
  /** The app's full content that the user will read */
  content: [],
  /** Menu items for the navigation based on the content */
  menu: [],
  /** Any error notifications we need the user to see */
  globalError: '',
  // contentLoading: false,
  // contentLoaded: false,
  // contentErrored: false,
  header: {
    isUnderFirstBlock: false,
    isStuck: false,
  },
  /** Used for auto-scrolling to different Sections */
  sectionRefs: {},
  isSectionWaypointEnabled: true,
  isGateReleased: false,
  selectedLocale: 'en',
};
