import React, { useState } from 'react';
import styled, { css } from 'styled-components/macro';
import Waypoint from '../Waypoint';
import { animated } from 'react-spring';
import { useCounter } from '../Counter';
import Container from '../Container';
import { LegendContainer, LegendItem, ChartTitle } from '../ChartElements';

/**
 * Validation for data structures returned from Contentful. More simplified than
 * the BarChart validation
 */
const checkDataStructure = data => {
  /** Check for basic structure */
  const isStructureCorrect = data && data.data && Array.isArray(data.data);

  if (!isStructureCorrect) return false;

  /**
   * Check the legend length against the data array, a nonexistent legend is
   * valid, but an empty or incomplete legend array is not
   */
  const isLegendLengthValid =
    !data.legend || (data.legend && data.legend.length === data.data.length);

  if (!isLegendLengthValid) return false;

  /** Make sure all items are numbers */
  const areValuesValid = data.data.every(d => !isNaN(+d));

  if (!areValuesValid) return false;

  if (isStructureCorrect && isLegendLengthValid && areValuesValid) {
    return true;
  }
  return false;
};

const Block = styled.div`
  margin: 2em 0;
  padding-bottom: ${props => (props.isLastItem ? '2em' : 0)};
`;

const BubbleContainer = styled.div`
  display: flex;
  /** Reverse so the stacking looks correct */
  /* flex-direction: row-reverse; */
  justify-content: center;
  align-items: flex-end;
  margin-bottom: 1em;
`;

const BubbleStyled = styled.span`
  flex: 0 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  /** Reverse the visual stacking order */
  z-index: ${props => -props.index + 5};
  text-align: center;
  /** Set the dimensions of the circle based on the value */
  ${props => {
    /** Don't let the size be above or below certain amounts */
    const size = `${((props.number < 40
      ? 40
      : props.number > 100
      ? 100
      : props.number) /
      100) *
      8}em`;
    return css`
      width: ${size};
      height: ${size};
    `;
  }}
  margin: 0 -0.2em;
  font-family: ${props => props.theme.fonts.sansSerif};
  color: ${props => props.theme.colors.white};
  font-size: 3rem;
  font-weight: 700;
  line-height: 1;

  &::before {
    content: '';
    display: block;
    z-index: -1;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 50%;
    left: 50%;
    border-radius: 100%;
    transform: ${props =>
      !props.shouldAnimate
        ? css`translate(-50%, -50%) scale(0)`
        : css`translate(-50%, -50%) scale(1)`};
    transition: transform 0.6s ease-out;
    transition-delay: ${props => `${props.index * 0.19}s`};
    background: ${props =>
      /** Allow the chart colors to cycle through the colors array */
      props.theme.charts[props.index % props.theme.charts.length] ||
      props.theme.colors.primary};
  }
`;

const Suffix = styled.span.attrs(props => ({ children: '%' }))`
  position: relative;
  font-size: 0.6em;
  line-height: 1;
  top: -0.3em;
`;

const Caption = styled.span`
  color: ${props => props.theme.text || props.theme.colors.primary};
  display: block;
  margin: 0 auto;
  text-align: center;
  font-size: 1.8rem;
  line-height: 1.3;
  font-family: ${props => props.theme.fonts.sansSerif};
  font-weight: 700;
`;

const Bubble = props => {
  const number = useCounter(0, props.number, props.shouldAnimate);
  return (
    <BubbleStyled
      number={props.number}
      index={props.index}
      shouldAnimate={props.shouldAnimate}
    >
      {/** Cheat the spacing for this webfont */}
      <animated.span style={{ margin: '0 0 -0.1em 0.3em' }}>
        {number}
      </animated.span>
      <Suffix>%</Suffix>
    </BubbleStyled>
  );
};

const Bubbles = ({ fields, ...props }) => {
  const [shouldAnimate, setAnimate] = useState(false);

  const { chartTitle, displayTheTitle, caption, chartData } = fields;

  const isValidData = checkDataStructure(chartData);
  /** Safety check */
  if (!isValidData) {
    console.warn('Data structure is not valid for Bubble Chart:', chartData);
  }

  /** Filter out bad values */
  const items = chartData ? chartData.data : [];
  const legend = chartData ? chartData.legend : [];

  return isValidData ? (
    <Waypoint
      topOffset="0"
      bottomOffset="25%"
      onEnter={() => setAnimate(true)}
      onLeave={() => setAnimate(false)}
    >
      <Block isLastItem={props.index + 1 === props.numberOfBlocks}>
        <Container>
          {chartTitle && displayTheTitle && (
            <ChartTitle>{chartTitle}</ChartTitle>
          )}
          {legend && legend.length && (
            <LegendContainer>
              {legend.map((l, i) => {
                return (
                  <LegendItem key={l} index={i} width={100 / legend.length}>
                    {l}
                  </LegendItem>
                );
              })}
            </LegendContainer>
          )}
          <BubbleContainer>
            {items.map((bubble, index) => (
              <Bubble
                key={bubble}
                number={bubble}
                index={index}
                shouldAnimate={shouldAnimate}
              ></Bubble>
            ))}
          </BubbleContainer>
          {caption && <Caption>{caption}</Caption>}
        </Container>
      </Block>
    </Waypoint>
  ) : null;
};

export default Bubbles;
