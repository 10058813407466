import React from 'react';
import styled, { css } from 'styled-components/macro';
import { transitions, transparentize } from 'polished';
import { Link } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';

export const ButtonStyles = css`
  appearance: none !important;
  background-color: ${({ theme }) => theme.colors.purple} !important;
  border-radius: 100px;
  color: ${({ theme }) => theme.colors.white} !important;
  font-weight: 500 !important;
  border: 1px solid ${({ theme }) => theme.colors.purple} !important;
  padding: 1em 1.5em !important;
  font-size: 1.8rem !important;
  line-height: 1.8 !important;
  min-width: 15rem !important;
  margin: 1em auto 0.5em !important;
  display: inline-block !important;
  cursor: pointer !important;
  text-align: center !important;
  font-variant-numeric: lining-nums !important;
  text-decoration: none;
  text-shadow: none;
  box-shadow: 0px 0px 25px -10px transparent;
  transform: translateY(0px);
  transform-style: preserve-3d;

  ${props =>
    transitions(
      ['transform', 'border-color', 'background-color', 'box-shadow'],
      props.theme.animations.ease
    )};

  &:hover,
  &:active,
  &:focus {
    background-color: ${({ theme }) => theme.colors.pink} !important;
    border-color: ${({ theme }) => theme.colors.pink} !important;

    box-shadow: 0px 10px 35px 0px
      ${({ theme }) => transparentize(0.75, theme.colors.black)};
    color: ${({ theme }) => theme.colors.white} !important;
    transform: translateY(-5px);
  }

  &:disabled {
    color: ${({ theme }) => theme.colors.grey} !important;
    background-color: ${({ theme }) => theme.colors.greyLight} !important;
    border-color: ${({ theme }) => theme.colors.grey} !important;
  }
`;

const StyledLink = styled.a`
  color: ${({ theme }) => theme.colors.pink};
  transition: ${({ theme }) => `color ${theme.animations.ease}`};

  &:hover {
    color: ${({ theme }) => theme.colors.purple};
  }
`;

export const StyledButton = styled.a`
  ${ButtonStyles}
`;

export const Button = props => {
  /**
   * Determine if a link is external by constructing a URL object from the prop,
   * then comparing the hostname of the URL with the window's hostname. If the
   * `href` prop is a relative link like '/test', the window.location will be
   * used as a base. If it's an absolute URL like 'https://google.com', the base
   * will be ignored
   */
  const isExternal =
    new URL(props.href, window.location).hostname !== window.location.hostname;

  // TODO: refactor Button and StyledLink to reuse logic
  const attrs = isExternal
    ? /** Add extra attributes for external links */
      {
        href: props.href,
        target: '_blank',
        rel: 'noopener noreferrer',
      }
    : /** Internal Link components need the "to" prop instead of "href" */
      {
        to: props.href,
      };

  return (
    <StyledButton
      {...props}
      {...attrs}
      as={isExternal ? undefined : Link}
      className={props.className}
    />
  );
};

export default props => {
  /** Destructure to remove any props that shouldn't be passed to the DOM element */
  const { isExternal, ...rest } = props;
  /**
   * Determine if a link is external by constructing a URL object from the prop,
   * then comparing the hostname of the URL with the window's hostname. If the
   * `href` prop is a relative link like '/test', the window.location will be
   * used as a base. If it's an absolute URL like 'https://google.com', the base
   * will be ignored
   */
  const isExternalLink = !!isExternal
    ? isExternal
    : new URL(props.href, window.location).hostname !==
      window.location.hostname;
  const isHashLink = !isExternalLink && /^#/.test(props.href);

  const attrs = isExternalLink
    ? /** Add extra attributes for external links */
      {
        href: props.href,
        target: '_blank',
        rel: 'noopener noreferrer',
      }
    : /** Internal Link components need the "to" prop instead of "href" */
      {
        to: props.href,
      };

  return (
    <StyledLink
      {...rest}
      {...attrs}
      /** If the link is internal, use a react-router Link */
      as={isHashLink ? HashLink : isExternalLink ? undefined : Link}
    />
  );
};
