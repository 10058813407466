import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import styled from 'styled-components/macro';
import { transitions } from 'polished';
import Icon from '../Icon';
import { generateTweetLink } from '../Tweet/Tweet';
import openPopup from './popup';
import { H3 } from '../Heading';
/** Use a separate icon from Tweet so we can maybe use something different */
import { ReactComponent as Twitter } from './twitter.svg';
import { ReactComponent as Facebook } from './facebook.svg';
import { ReactComponent as LinkedIn } from './linkedin.svg';
// import { ReactComponent as Email } from './email.svg';
import config from '../../config';

/** If you want to add borders or anything else, add them here */
const ShareWrapper = styled.div`
  padding-top: 6em;
  padding-bottom: 4em;
  text-align: center;
  background-color: ${({ theme }) => theme.colors.greyLightest};
  position: relative;
  z-index: 20;
`;

/**
 * Depending on how the heading is styled, you could just pull in the
 * appropriate Heading component and add any extra styles, e.g. styled(H3)``
 */
const ShareHeading = styled(H3)`
  margin-bottom: 0.25em;
`;

const ShareIconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

/** TODO: add theme options for backgrounds and the shape of the bg (circle, square, etc) */
/** Add some common link attributes to make them behave the same */
const ShareLink = styled.a.attrs(props => ({
  onClick: openPopup,
  /** In case the popup doesn't work */
  target: '_blank',
  rel: 'noopener noreferrer',
}))`
  display: inline-block;
  width: 2em;
  height: 2em;
  border-radius: 50%;
  padding: 0.6em;
  margin: 0.6em;
  text-decoration: none;
  color: ${({ theme }) => theme.colors.white};
  background-color: ${({ theme }) => theme.colors.purple};
  ${({ theme }) => transitions(['background-color'], theme.animations.ease)}

  &:hover {
    background-color: ${({ theme }) => theme.colors.pink};
  }
`;

const ShareIcon = styled(Icon)`
  width: 100%;
  height: 100%;
`;

const SocialShare = props => {
  /** Get the full URL and take away the hash so we always share the root */
  const lightpaperRoot = encodeURIComponent(
    window.location.href.split('/#')[0]
  );

  return (
    <ShareWrapper>
      {props.heading && <ShareHeading>{props.heading}</ShareHeading>}
      <ShareIconContainer>
        <ShareLink
          href={generateTweetLink([props.title], null, config.twitterHandle)}
        >
          <ShareIcon icon={Twitter} />
        </ShareLink>
        <ShareLink
          href={`https://www.facebook.com/sharer.php?u=${lightpaperRoot}`}
        >
          <ShareIcon icon={Facebook} />
        </ShareLink>
        <ShareLink
          href={`https://www.linkedin.com/shareArticle?mini=true&url=${lightpaperRoot}&title=${encodeURIComponent(
            props.title
          )}`}
        >
          <ShareIcon icon={LinkedIn} />
        </ShareLink>
        {/** TODO: Get the root meta title for the subject */}
        {/* <ShareLink onClick={() => {}} href={`mailto:?body=${emailBody}&subject=${emailSubject}`}>
          <ShareIcon icon={Email} />
        </ShareLink> */}
      </ShareIconContainer>
    </ShareWrapper>
  );
};

SocialShare.propTypes = {
  /** Can accept a React class or function component, or just a string */
  heading: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.func,
    PropTypes.string,
  ]),
};

SocialShare.defaultProps = {
  heading: null,
};

const mapStateToProps = state => ({
  title: state.content[0].metaTitle,
});

export default connect(mapStateToProps)(SocialShare);
